//Citation for the following MoonsTableRow file and TableRow function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
const TableRow = ({ moon, fetchMoons }) => {
  // Hook that allows us to navigate programmatically
  const navigate = useNavigate();
  // Redirect to edit person page
  const handleEdit = () => {
    // We can access the id (and query the person) with useParams() in the UpdateMoon component
    navigate("/moons/edit/" + moon.moonID, { state: { moon } });
  };

  // Function handles deletion of a moon row in the table by passing the moonID to the backend
  const deleteRow = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "moons/" + moon.moonID;
      const response = await axios.delete(URL);
      
      // Ensure that the moon was deleted successfully
      if (response.status === 204) {
        alert("Moon deleted successfully");
      }
    } catch (err) {
      alert(err.response.data.error || "Error deleting moon");
      console.log(err);
    }
    fetchMoons();
  };

  return (
    <Tr key={moon.moonID}> {/*Display values from moon object in state variable passed in from MoonsTable  */}
      <Td>{moon.moonID}</Td>
      <Td>{moon.name}</Td>
      <Td>{moon.visitCost}</Td>
      <Td>{moon.riskLevel}</Td>
      <Td>
        <BiEditAlt onClick={handleEdit} size={25} style={{ cursor: "pointer" }} />
        </Td>
      <Td>
        <BsTrash onClick={deleteRow} size={25} style={{ cursor: "pointer" }} />
        </Td>
      </Tr>
  );
};

export default TableRow;
