import { useState, useEffect } from "react";
import { RiCreativeCommonsZeroFill } from "react-icons/ri";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import TableRow from "./MonstersTableRow";
import axios from "axios";
import { 
  Box, 
  Heading, 
  Flex, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  TableContainer, 
  Text,
  Input,
  Select,
  HStack,
  VStack,
  Stack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

const MonstersTable = () => {
  const [monsters, setMonsters] = useState([]);
  const [sortBy, setSortBy] = useState('monsterID');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    species: '',
    killable: '',
    location: '',
    hostile: ''
  });

  const fetchMonsters = async () => {
    try {
      // Build query parameters
      const params = new URLSearchParams({
        sortBy,
        sortOrder,
        ...Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== '')
        )
      });

      const URL = `${import.meta.env.VITE_API_URL}monsters?${params}`;
      const response = await axios.get(URL);
      setMonsters(response.data);
    } catch (error) {
      alert("Error fetching monsters from the server.");
      console.error("Error fetching monsters:", error);
    }
  };

  useEffect(() => {
    fetchMonsters();
  }, [sortBy, sortOrder, filters]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Box p={4} maxWidth="1000px" mx="auto" textAlign="center">
      <Text mb={4}>
        The table below displays all encountered monsters. Click the button above to add a new monster. 
        To update or delete a monster, click the respective button in the same row as the monster.
      </Text>
  
      {/* Filter Controls */}
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
        <Wrap spacing={{ base: 2, md: 4 }} justify="center">
          <WrapItem>
            <Input
              placeholder="Filter by Species"
              value={filters.species}
              onChange={(e) => handleFilterChange('species', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Location"
              value={filters.location}
              onChange={(e) => handleFilterChange('location', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            >
              <option value="Indoor">Indoor</option>
              <option value="Outdoor">Outdoor</option>
            </Select>
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Killable"
              value={filters.killable}
              onChange={(e) => handleFilterChange('killable', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Select>
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Hostile"
              value={filters.hostile}
              onChange={(e) => handleFilterChange('hostile', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </Select>
          </WrapItem>
        </Wrap>
      </Box>
  
      {monsters.length === 0 ? (
        <Box as="header" py={5} px={10} textAlign="center">
          <Flex align="center" justify="center" direction="column">
            <RiCreativeCommonsZeroFill size={70} color="gray" />
            <Text bg='black' px={4} py={1} mt={5}>No monsters in Monsters found.</Text>
          </Flex> 
        </Box>
      ) : (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {[
                  { id: 'monsterID', label: 'ID' },
                  { id: 'species', label: 'Species' },
                  { id: 'killable', label: 'Killable' },
                  { id: 'location', label: 'Location' },
                  { id: 'hostile', label: 'Hostile' }
                ].map(column => (
                  <Th 
                    key={column.id}
                    onClick={() => handleSort(column.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <span>{column.label}</span>
                      {getSortIcon(column.id)}
                    </Stack>
                  </Th>
                ))}
                <Th>Edit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {monsters.map((monster) => (
                <TableRow 
                  key={monster.monsterID} 
                  monster={monster} 
                  fetchMonsters={fetchMonsters} 
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default MonstersTable;