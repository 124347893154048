//Citation for the following CreateTripsHasMonsters file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

function CreateTripsHasMonsters() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    tripID: "",
    monsterID: "",
    killed: ""
  });
  
  const handleSubmit = async (e) => {
    // Prevent page reload
    e.preventDefault();
    // Create a new monster object from the formData
    const CreateTripsHasMonsters = {
      tripID: formData.tripID,
      monsterID: formData.monsterID,
      killed: formData.killed
    };

    try {
      const URL = import.meta.env.VITE_API_URL + "tripshasmonsters";
      const response = await axios.post(URL, newTripsHasMonsters);
      if (response.status === 201) {
        navigate("/tripshasmonsters");
      } else {
        alert("Error creating tripshasmonsters");
      }
    } catch (error) {
      alert("Error creating tripshasmonsters");
      console.error("Error creating monster:", error);
    }
    // Reset the form fields
    resetFormFields();
  };

  const resetFormFields = () => {
    setFormData({
      tripID: "",
      monsterID: "",
      killed: ""
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <h2>Create TripsHasMonsters</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="tripID">tripID</label>
        <input
          type="text"
          name="tripID"
          defaultValue={formData.tripID}
          onChange={handleInputChange}
        />
        <label htmlFor="monsterID">monsterID</label>
        <input
          type="text"
          name="monsterID"
          defaultValue={formData.monsterID}
          onChange={handleInputChange}
        />
        <label htmlFor="killed">Killed</label>
        <input
          type="text"
          name="killed"
          value={formData.killed}
          onChange={handleInputChange}
        />
        <button type="submit">Create TripsHasMonsters</button>
      </form>
    </>
  );
}

export default CreateTripsHasMonsters;
