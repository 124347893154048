import { useState, useEffect } from "react";
import { RiCreativeCommonsZeroFill } from "react-icons/ri";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import TableRow from "./TripsHasMonstersTableRow";
import axios from "axios";
import { 
  Box, 
  Heading, 
  Flex, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  TableContainer, 
  Text,
  Input,
  Select,
  HStack,
  Stack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

const TripsHasMonstersTable = () => {
  const [tripshasmonsters, setTripsHasMonsters] = useState([]);
  const [sortBy, setSortBy] = useState('tripMonsterID');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    trip: '',
    species: '',
    killed: '',
    moonName: '',
    date: ''
  });

  const fetchTripsHasMonsters = async () => {
    try {
      // Build query parameters
      const params = new URLSearchParams({
        sortBy,
        sortOrder,
        ...Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== '')
        )
      });

      const URL = `${import.meta.env.VITE_API_URL}tripshasmonsters?${params}`;
      const response = await axios.get(URL);
      setTripsHasMonsters(response.data);
      console.log("API Response:", response.data);
    } catch (error) {
      alert("Error fetching tripshasmonsters from the server.");
      console.error("Error fetching tripshasmonsters:", error);
    }
  };

  useEffect(() => {
    fetchTripsHasMonsters();
  }, [sortBy, sortOrder, filters]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Box p={4} maxWidth="1000px" mx="auto" textAlign="center">
      <Text mb={4}>
        The table below displays all encounters with monsters on recorded trips. 
        To create, update, or delete a monster encounter for a trip, please use the update button on the trips page.
      </Text>
  
      {/* Filter Controls */}
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
        <Wrap spacing={{ base: 2, md: 4 }} justify="center">
          <WrapItem>
            <Input
              placeholder="Filter by Trip Info"
              value={filters.trip}
              onChange={(e) => handleFilterChange('trip', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Moon"
              value={filters.moonName}
              onChange={(e) => handleFilterChange('moonName', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              type="date"
              value={filters.date}
              onChange={(e) => handleFilterChange('date', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Species"
              value={filters.species}
              onChange={(e) => handleFilterChange('species', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Killed Status"
              value={filters.killed}
              onChange={(e) => handleFilterChange('killed', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </WrapItem>
        </Wrap>
      </Box>
  
      {tripshasmonsters.length === 0 ? (
        <Box as="header" py={5} px={10} textAlign="center">
          <Flex align="center" justify="center" direction="column">
            <RiCreativeCommonsZeroFill size={70} color="gray" />
            <Text bg='black' px={4} py={1} mt={5}>No entries in TripsHasMonsters found matching the current filters.</Text>
          </Flex> 
        </Box>
      ) : (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {[
                  { id: 'tripMonsterID', label: 'ID' },
                  { id: 'trip', label: 'Trip Details' },
                  { id: 'species', label: 'Monster Species' },
                  { id: 'killed', label: 'Killed?' }
                ].map(column => (
                  <Th 
                    key={column.id}
                    onClick={() => handleSort(column.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <span>{column.label}</span>
                      {getSortIcon(column.id)}
                    </Stack>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {tripshasmonsters.map((monster) => (
                <TableRow 
                  key={monster.tripMonsterID}
                  tripshasmonsters={monster}
                  fetchTripsHasMonsters={fetchTripsHasMonsters}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TripsHasMonstersTable;