//Citation for the following employeesDropdown file and function:
//Date: 05/21/2024
// fetchEmployees function and mapping method of response from fetchEmployees to dropdown list inspired from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

// defaultValue is the employee used by default when updating a trip if previously selected
const EmployeesDropdown = ({ onChange, defaultValue }) => {
  // declare state variable for employees and setEmployees to update state variable. Holds the list of employees fetched from the backend.
  const [employees, setEmployees] = useState([]);

  // declare state variable for employeeID and setSelectedEmployeeID to update state variable. Holds the ID of the selected employee.
  const [selectedEmployeeID, setSelectedEmployeeID] = useState("");

  // effect hook to fetch employees, rerenders when there is a change to the defaultValue prop 
  useEffect(() => {
    fetchEmployees();
  }, [defaultValue]);

  // retrieve all employees stored in Employees table
  const fetchEmployees = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + 'employees';
      const response = await axios.get(URL);
      console.log('employees:',response.data);
      // Update employees state variable with employees fetched from Employees table
      setEmployees(response.data);

      // Map defaultValue (employee name) to employeeID
      // If an employee was already filled previously (meaning the trips form is being updated or the user selected an employee from the dropdown), map that employee to defaultEmployee
      if (defaultValue) {
        const defaultEmployee = response.data.find(employee => `${employee.firstName} ${employee.lastName}` === defaultValue);
        // if a default employee is present, set the employeeID to that employee
        if (defaultEmployee) {
          // console.log('Default employee found:', defaultEmployee);
          // Update employeeID state variable to employeeID found from defaultEmployee
          setSelectedEmployeeID(defaultEmployee.employeeID);
          // handler for selecting an employee
          onChange(defaultEmployee);
        } 
      } 
    } catch (error) {
      console.error('Error fetching employees:', error);
      alert('Error fetching employees from the server.');
    }
  };

  // Handler for when the employee dropdown value changes
  const handleChange = (event) => {
    const employeeID = event.target.value;
    console.log('event.target.value:',event.target.value ); // debugging statement, to be deleted later
    setSelectedEmployeeID(employeeID);
    const selectedEmployee = employees.find(employee => employee.employeeID === parseInt(employeeID));
    onChange(selectedEmployee);
  };

  return (
    <FormControl isRequired>
      <Select placeholder="Select an Employee" onChange={handleChange} value={selectedEmployeeID} bg="rgba(0, 0, 0, 0.80)" variant='filled'>
        <option value={null}>N/A</option>
        {employees.map((employee) => (
          <option key={employee.employeeID} value={employee.employeeID}>
            {`${employee.firstName} ${employee.lastName}`}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default EmployeesDropdown;