//Citation for the following CreateEmployee file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citation for updating placeholder text color:
// Date: 05/30/2024
// Adapted from the following URL:
// Source URL: https://github.com/chakra-ui/chakra-ui/discussions/3110#discussioncomment-297903

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

function CreateEmployee() {
  const navigate = useNavigate();

  // formData state variable that holds data to send to backend when form is submitted
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    rank: "",
    status: "",
  });
  
  const handleSubmit = async (e) => {
    // Prevent page reload
    e.preventDefault();
    // Create a new employee object from the formData
    const newEmployee = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      rank: formData.rank,
      status: formData.status,
    };

    try {
      const URL = import.meta.env.VITE_API_URL + "employees";
      const response = await axios.post(URL, newEmployee);
      if (response.status === 201) {
        navigate("/employees");
        alert("Successfully created employee");
      } else {
        alert("Error creating employee");
      }
    } catch (error) {
      alert("Error creating employee");
      console.error("Error creating employee:", error);
    }
    // Reset the form fields
    resetFormFields();
  };

  // reset form state variable when form is reset
  const resetFormFields = () => {
    setFormData({
      firstName: "",
      lastName: "",
      rank: "",
      status: "",
    });
  };

  // Handler for when the input fields for firstName, lastName, rank and status are updated
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Heading as="h2" size="md" mb={4}>Create Employee</Heading>
      
      <Box as="form" onSubmit={handleSubmit}>
        {/* Employee firstName Input field on form */}
        <FormControl id="firstName" isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="firstName"
            defaultValue={formData.firstName}
            onChange={handleInputChange}
            required
            placeholder='Enter First Name'
            bg="rgba(0, 0, 0, 0.80)"
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        {/* Employee lastName Input field on form */}
        <FormControl id="lastName" isRequired mt={4}>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="lastName"
            defaultValue={formData.lastName}
            onChange={handleInputChange}
            required
            placeholder='Enter Last Name'
            bg="rgba(0, 0, 0, 0.80)"
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        {/* Employee rank select dropdown field on form */}
        <FormControl id="rank" isRequired mt={4}>
          <FormLabel>Rank</FormLabel>
          <Select name="rank" value={formData.rank} onChange={handleInputChange} placeholder='Select Rank' required bg="rgba(0, 0, 0, 0.80)" variant='filled'>
            <option value="Intern">Intern</option>
            <option value="Part-Timer">Part-Timer</option>
            <option value="Employee">Employee</option>
            <option value="Leader">Leader</option>
            <option value="Boss">Boss</option>
          </Select>
        </FormControl>

        {/* Employee status select dropdown field on form */}
        <FormControl id="status" isRequired mt={4}>
          <FormLabel>Status</FormLabel>
          <Select name="status" value={formData.status} onChange={handleInputChange} placeholder='Select Status' required bg="rgba(0, 0, 0, 0.80)" variant='filled'>
            <option value="0">Dead</option>
            <option value="1">Alive</option>
          </Select>
        </FormControl>

        <Box mt={4}>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Create Employee
          </Button>
        </Box>
      </Box>
    </>
  );
}


export default CreateEmployee;
