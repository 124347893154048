//Citation for the following EmployeesTableRow file and TableRow function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app


import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
const TableRow = ({ employee, fetchEmployees }) => {
  // Hook that allows us to navigate programmatically
  const navigate = useNavigate();
  // Redirect to edit person page
  const handleEdit = () => {
    // We can access the id (and query the person) with useParams() in the UpdateEmployee component
    navigate("/employees/edit/" + employee.employeeID, { state: { employee } });
    
  };

  const deleteRow = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "employees/" + employee.employeeID;
      const response = await axios.delete(URL);
      // Ensure that the employee was deleted successfully
      if (response.status === 204) {
        alert("Employee deleted successfully");
      }
    } catch (err) {
      alert(err.response.data.error || "Error deleting employee");
      console.log(err);
    }
    fetchEmployees();
  };

  // Since user enters in "dead" and "alive" values from dropdown on user form, and status field is TINYINT in backend database, map "alive" and "dead" to 1 and 0
  const statusMapping = {
    0: "Dead",
    1: "Alive"
  };
 
  return (
    <Tr key={employee.employeeID}>  {/*Display values from employee object in state variable passed in from EmployeesTable  */}
      <Td>{employee.employeeID}</Td>
      <Td>{employee.firstName}</Td>
      <Td>{employee.lastName}</Td>
      <Td>{employee.rank}</Td>
      <Td>{statusMapping[employee.status]}</Td>
      <Td>
        <BiEditAlt onClick={handleEdit} size={25} style={{ cursor: "pointer" }} />
      </Td>
      <Td>
        <BsTrash onClick={deleteRow} size={25} style={{ cursor: "pointer" }} />
      </Td>
    </Tr>
  );
};

export default TableRow;
