//Citation for the following App.jsx file:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import EmployeesPage from "./pages/EmployeesPage";
import MonstersPage from "./pages/MonstersPage";
import LootItemsPage from "./pages/LootItemsPage";
import MoonsPage from "./pages/MoonsPage";
import TripsPage from "./pages/TripsPage";
import TripsHasMonstersPage from "./pages/TripsHasMonstersPage";
import TripsHasLootItemsPage from "./pages/TripsHasLootItemsPage";
import Navbar from "./components/navbar/NavBar";
import { Box } from "@chakra-ui/react";

function App() {
  return (
    <>
      <Navbar />
      <Box pt="0px" maxW="100%" overflowX="auto">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/employees/*" element={<EmployeesPage />} />
        <Route path="/monsters/*" element={<MonstersPage />} />
        <Route path="/lootitems/*" element={<LootItemsPage />} />
        <Route path="/moons/*" element={<MoonsPage />} />
        <Route path="/trips/*" element={<TripsPage />} />
        <Route path="/tripshasmonsters/*" element={<TripsHasMonstersPage />} />
        <Route path="/tripshaslootitems/*" element={<TripsHasLootItemsPage />} />
      </Routes>
      </Box>
    </>
  );
}

export default App;