//Citation for the following MonstersPage file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import { Routes, Route, Link } from "react-router-dom";
import CreateMonster from "../components/Monsters/CreateMonster";
import MonstersTable from "../components/Monsters/MonstersTable";
import UpdateMonster from "../components/Monsters/UpdateMonster";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Text} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { BiPlus, BiTable } from "react-icons/bi";

function MonstersPage() {
  const location = useLocation();
  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={4}>Monsters</Heading>
      <Flex as="nav" justify="center" mb={4}>

      {(location.pathname == "/monsters/add" || location.pathname.startsWith( "/monsters/edit/"))  && (
        <Button as={Link} to="/monsters" mr={2} leftIcon={<BiTable />} _hover={{ bg: "#7cfc7c" }}>
          Monsters Table
        </Button>
      )}
      
      {location.pathname === "/monsters" && (<Button as={Link} to="/monsters/add" mr={2} leftIcon={<BiPlus />} _hover={{ bg: "#7cfc7c" }}>
          Add Monster
        </Button>
      )}
      </Flex>
      <Routes>
        <Route path="/" element={<MonstersTable />} />
        <Route path="/add" element={<CreateMonster />} />
        <Route path="/edit/:id" element={<UpdateMonster />} />
      </Routes>
      </Box>
  );
}

export default MonstersPage;
