import { useState, useEffect } from "react";
import { RiCreativeCommonsZeroFill } from "react-icons/ri";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import TableRow from "./LootItemsTableRow";
import axios from "axios";
import { 
  Box, 
  Heading, 
  Flex, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  TableContainer, 
  Text,
  Input,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Wrap,
  WrapItem, 
  Stack
} from "@chakra-ui/react";

const LootItemsTable = () => {
  const [lootItems, setLootItems] = useState([]);
  const [sortBy, setSortBy] = useState('lootItemID');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    name: '',
    value: '',
    weight: '',
    action: ''
  });

  const fetchLootItems = async () => {
    try {
      // Build query parameters
      const params = new URLSearchParams({
        sortBy,
        sortOrder,
        ...Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== '')
        )
      });

      const URL = `${import.meta.env.VITE_API_URL}lootitems?${params}`;
      const response = await axios.get(URL);
      setLootItems(response.data);
    } catch (error) {
      alert("Error fetching loot items from the server.");
      console.error("Error fetching loot items:", error);
    }
  };

  useEffect(() => {
    fetchLootItems();
  }, [sortBy, sortOrder, filters]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Box p={4} maxWidth="1000px" mx="auto" textAlign="center">
      <Text mb={4}>
        The table below displays all types of loot items discovered. Click the button above to add a new loot item. 
        To update or delete loot item, click the respective button in the same row as the loot item.
      </Text>
  
      {/* Filter Controls */}
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
        <Wrap spacing={{ base: 2, md: 4 }} justify="center">
          <WrapItem>
            <Input
              placeholder="Filter by Name"
              value={filters.name}
              onChange={(e) => handleFilterChange('name', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <NumberInput
              min={0}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
              value={filters.value}
              onChange={(valueString) => handleFilterChange('value', valueString)}
            >
              <NumberInputField placeholder="Filter by Value" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </WrapItem>
          <WrapItem>
            <NumberInput
              min={0}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
              value={filters.weight}
              onChange={(valueString) => handleFilterChange('weight', valueString)}
            >
              <NumberInputField placeholder="Filter by Weight" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Action"
              value={filters.action}
              onChange={(e) => handleFilterChange('action', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
        </Wrap>
      </Box>
  
      {lootItems.length === 0 ? (
        <Box as="header" py={5} px={10} textAlign="center">
          <Flex align="center" justify="center" direction="column">
            <RiCreativeCommonsZeroFill size={70} color="gray" />
            <Text bg='black' px={4} py={1} mt={5}>No loot items in LootItems found.</Text>
          </Flex> 
        </Box>
      ) : (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {[
                  { id: 'lootItemID', label: 'ID' },
                  { id: 'name', label: 'Name' },
                  { id: 'value', label: 'Value' },
                  { id: 'weight', label: 'Weight (lbs)' },
                  { id: 'action', label: 'Action' }
                ].map(column => (
                  <Th 
                    key={column.id}
                    onClick={() => handleSort(column.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <span>{column.label}</span>
                      {getSortIcon(column.id)}
                    </Stack>
                  </Th>
                ))}
                <Th>Edit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {lootItems.map((lootItem) => (
                <TableRow 
                  key={lootItem.lootItemID} 
                  lootItem={lootItem} 
                  fetchLootItems={fetchLootItems} 
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default LootItemsTable;