//Citation for the following UpdateMonster file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citation for updating placeholder text color:
// Date: 05/30/2024
// Adapted from the following URL:
// Source URL: https://github.com/chakra-ui/chakra-ui/discussions/3110#discussioncomment-297903

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

const UpdateMonster = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevMonster = location.state.monster;

  // holds state variable for form data and its set function. Data in this state variable is sent to the backend when form is submitted
  const [formData, setFormData] = useState({
    species: prevMonster.species || '',
    killable: prevMonster.killable === 0 ? '0' : '1', // Set killable to 0 if user previously selected no, otherwise set to 1
    location: prevMonster.location || '',
    hostile: prevMonster.hostile === 0 ? '0' : '1', // Set hostile to 0 if user previously selected no, otherwise set to 1
  });

  // Handler for when the input fields for species, killable, location and hostile input/select fields are updated
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Checks if updated form data is same as previous form data and doesn't perform any updates if so
  function isUpdate(){
    // Check if formData is equal to prevMonster
    if (JSON.stringify(formData) === JSON.stringify({
      species: prevMonster.species || '',
      killable: prevMonster.killable === 0 ? '0' : '1',
      location: prevMonster.location || '',
      hostile: prevMonster.hostile === 0 ? '0' : '1', 
    })) {
      alert("No changes made.");
      return false;
    }
    return true
  }

  // Handler for when the monsters form is submitted
  const handleSubmit = async (event) => {
    // Stop default form behavior which is to reload the page
    event.preventDefault();
    // Check if formData is equal to prevMonster
    if (isUpdate()){
      try {
        const URL = import.meta.env.VITE_API_URL + "monsters/" + id;
        const response = await axios.put(URL, formData);
        if (response.status !== 200) {
          alert("Error updating monster");
        } else {
          alert(response.data.message);
          // Redirect to monsters page
          navigate("/monsters");
        }
      } catch (err) {
        console.log("Error updating monster:", err);
      }
    }
  };

  useEffect(() => {
    console.log('prevMonster:',prevMonster);
    console.log('formData:',formData);
  }, [prevMonster, formData]);

  return (
    <Box p={4}>
      <Heading as="h2" size="md" mb={4}>Update Monster</Heading>
      <Box as="form" onSubmit={handleSubmit}>

        {/* Monster Species Input field on form */}
        <FormControl id="species" isRequired>
          <FormLabel>Species</FormLabel>
          <Input
            type="text"
            name="species"
            onChange={handleInputChange} // Handler for when input field value changes
            defaultValue={prevMonster.species} // set value to species stored in formData state variable
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Monster Species Name'
            _placeholder={{ color: '#00ff00' }} 
          />
        </FormControl>

        {/* Killable dropdown field on form */}
        <FormControl id="killable" isRequired mt={4}>
          <FormLabel>Killable</FormLabel>
          <Select 
            name="killable" 
            value={formData.killable} // set value to killable stored in formData state variable
            onChange={handleInputChange} // Handler for when select field value changes
            required
            placeholder='Select Killable Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Select>
        </FormControl>

        {/* Monster location Input field on form */}
        <FormControl id="location" isRequired mt={4}>
          <FormLabel>Location</FormLabel>
          <Select
            type="text"
            name="location"
            onChange={handleInputChange} // Handler for when input field value changes
            defaultValue={prevMonster.location} // set value to location stored in formData state variable
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Monster Location' 
            _placeholder={{ color: '#00ff00' }}
          >
            <option value="Indoor">Indoor</option>
            <option value="Outdoor">Outdoor</option>
          </Select>
        </FormControl>

        {/* Hostile dropdown field on form */}
        <FormControl id="hostile" isRequired mt={4}>
          <FormLabel>Hostile</FormLabel>
          <Select 
            name="hostile" 
            value={formData.hostile} // set value to hostile stored in formData state variable
            onChange={handleInputChange} // Handler for when select field value changes
            required
            placeholder='Select Hostile Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Select>
        </FormControl>

        <Box mt={4} display="flex" justifyContent="space-between">
          <Button _hover={{ bg: "#7cfc7c" }} onClick={() => navigate("/monsters")}>
            Cancel
          </Button>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateMonster;
