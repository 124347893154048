//Citation for the following Navbar file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citations for Chakra UI Configurations:
// Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Lethal Company Favicon and NavBar Icon picture for src="/lethal-company-steamdb.png" alt="Lethal Company Icon" - taken from the following:
// Source URL: https://www.steamgriddb.com/icon/48097


import { Link as RouterLink} from "react-router-dom";
import { Box, Button, Flex, Heading, Image, Link as ChakraLink, Wrap, WrapItem} from "@chakra-ui/react";

const Navbar = () => {
  return (
    <Box as="header" py={5} px={10} textAlign="center">
      <Flex align="center" justify="center">
        <Flex align="center">
          <ChakraLink as={RouterLink} to="/" mr={4}>
            <Image src="/lethal-company-steamdb.png" alt="Lethal Company Icon" boxSize="50px" />
          </ChakraLink>
          <Box flex ="1" textAlign="center"> 
            <Heading as="h1" size="xl">Lethal Database</Heading>
          </Box>
        </Flex>
      </Flex>
      <Wrap justify="center" my={5}>
        <WrapItem>
        <Button as={RouterLink} to="/" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Home</Button>
        </WrapItem>
        <WrapItem>
          <Button as={RouterLink} to="/employees" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Employees</Button>
        </WrapItem>
        <WrapItem>
          <Button as={RouterLink} to="/monsters" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Monsters</Button>
        </WrapItem>
        <WrapItem>
          <Button as={RouterLink} to="/lootitems" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Loot Items</Button>
        </WrapItem>
        <WrapItem>
          <Button as={RouterLink} to="/moons" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Moons</Button>
        </WrapItem>
        <WrapItem>
          <Button as={RouterLink} to="/trips" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Trips</Button>
        </WrapItem>
        <WrapItem>
          <Button as={RouterLink} to="/tripshasmonsters" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Monster Encounters</Button>
        </WrapItem>
        <WrapItem>
          <Button as={RouterLink} to="/tripshaslootitems" bg="rgba(0, 0, 0, 0.25)" color="#00FF00"  borderColor="#00FF00" variant="solid" borderRadius="0" border="dotted 2px" _hover={{ bg: "#1a1a1a" }} _active={{ bg: "#333333" }}>Collected Loot</Button>
        </WrapItem>
      </Wrap>
    </Box>
  );
};

export default Navbar;