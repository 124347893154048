//Citation for the following TripsHasMonstersTableRow file and TableRow function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
const TableRow = ({ tripshasmonsters, fetchTripsHasMonsters}) => {
  // Hook that allows us to navigate programmatically
  const navigate = useNavigate();
  // Redirect to edit person page
  const handleEdit = () => {
    // We can access the id (and query the person) with useParams() in the UpdateMonster component
    navigate("/tripshasmonsters/edit/" + tripshasmonsters.tripMonsterID, { state: { tripshasmonsters } });
  };

  const deleteRow = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "tripshasmonsters/" + tripshasmonsters.tripMonsterID;
      const response = await axios.delete(URL);
      // Ensure that the monster was deleted successfully
      if (response.status === 204) {
        alert("Monster deleted successfully");
      }
    } catch (err) {
      alert(err.response.data.error || "Error deleting monster");
      console.log(err);
    }
    fetchTripsHasMonsters();
  };

  return (
    <Tr>
      <Td>{tripshasmonsters.tripMonsterID}</Td>
      <Td>{tripshasmonsters.trip}</Td>
      <Td>{tripshasmonsters.species}</Td>
      <Td>{tripshasmonsters.killed}</Td>
      {/*<Td>
        <BiEditAlt onClick={handleEdit} size={25} style={{ cursor: "pointer" }} />
      </Td>
      <Td>
        <BsTrash onClick={deleteRow} size={25} style={{ cursor: "pointer" }} />
      </Td> */}
    </Tr>
  );
};

export default TableRow;