//Citation for the following MonstersTableRow file and TableRow function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
const TableRow = ({ monster, fetchMonsters}) => {
  // Hook that allows us to navigate programmatically
  const navigate = useNavigate();
  // Redirect to edit person page
  const handleEdit = () => {
    // We can access the id (and query the person) with useParams() in the UpdateMonster component
    navigate("/monsters/edit/" + monster.monsterID, { state: { monster } });
  };

  const deleteRow = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "monsters/" + monster.monsterID;
      const response = await axios.delete(URL);
      // Ensure that the monster was deleted successfully
      if (response.status === 204) {
        alert("Monster deleted successfully");
      }
    } catch (err) {
      alert(err.response.data.error || "Error deleting monster");
      console.log(err);
    }
    fetchMonsters();
  };

  // Since user enters in "yes" and "no" values from dropdown on user form, and killable and hostile fields are TINYINT in backend database, map "yes" and "no" to 1 and 0
  const monstersMapping = {
    0: "No",
    1: "Yes"
  }

  return (
    <Tr key={monster.monsterID}> {/*Display values from monster object in state variable passed in from Monsters Table  */}
      <Td>{monster.monsterID}</Td>
      <Td>{monster.species}</Td>
      <Td>{monstersMapping[monster.killable]}</Td> {/*Map "yes" or "no" value to 1 or 0 per mapping defined */}
      <Td>{monster.location}</Td>
      <Td>{monstersMapping[monster.hostile]}</Td> {/*Map "yes" or "no" value to 1 or 0 per mapping defined */}
      <Td>
        <BiEditAlt onClick={handleEdit} size={25} style={{ cursor: "pointer" }} />
        </Td>
      <Td>
        <BsTrash onClick={deleteRow} size={25} style={{ cursor: "pointer" }} />
        </Td>
      </Tr>
  );
};

export default TableRow;
