import ResetButton from "../components/Buttons/ResetButton";
import { Box, Heading, Image, Text, Stack } from '@chakra-ui/react';

function HomePage() {
  return (
    <Box textAlign="center" py={[4, 6, 10]} px={[4, 6, 8]}>
      <Heading as="h1" size="xl" mb={[6, 8, 10]}>
        Home Page
      </Heading>
      
      <Stack
        direction={['column', 'column', 'row']}
        spacing={[6, 8, 10]}
        align="center"
        justify="center"
        maxW='1000px'
        mx="auto"
        mb={5}
      >
        <Image
          src="/lethal-company-lethal-company-dance.gif"
          alt="Lethal Company Dancing GIF"
          maxW={['100%', '100%', '50%']}
          objectFit="cover"
        />
        
        <Stack spacing={5} textAlign={['left', 'left', 'left']} flex="1">
          <Text>
            Welcome to The Company, an intergalactic scrap-collecting organization as featured in the popular game Lethal Company. We operate across 12 hazardous moons, with 100 brave employees venturing out daily to gather precious scrap for us to sell. To enhance our operational efficiency, we created a new database solution to meticulously track our employees' trips, performance, and encounters in the field.
          </Text>
          
          <Text>
            Our previous system only measured success by the pile of scrap returned, but this new database provides comprehensive insights into each trip. It includes detailed records of the locations visited, loot collected, and monsters encountered. With approximately 50 different possible loot items and 20 different possible monsters, our database will help us identify the strengths and areas for improvement among our employees, as well as better prepare for the challenges each moon presents.
          </Text>
          
          <Text>
            Created by: Fadi Othman and Emily Huntley
          </Text>
        </Stack>
      </Stack>

      <Box mt={[4, 6, 8]}>
        <ResetButton />
      </Box>
    </Box>
  );
}

export default HomePage;