//Citation for the following LootItemsDropdown file and function:
//Date: 05/17/2024
// Using .map() and an arrow function to return a modified lootItem object from the lootItems array with an additional attribute/property for being checked in 'lootItemsWithChecked' was inspired by: 
//Source URL: https://stackoverflow.com/a/71558196

//Date: 05/17/2024
// Implementation and handling of checkboxes was partially inspired by:  
//Source URL: https://dev.to/collegewap/how-to-work-with-checkboxes-in-react-44bc
//Source URL: https://www.freecodecamp.org/news/how-to-work-with-multiple-checkboxes-in-react/

// Citations for Chakra UI Configurations:
//Date: 05/29/2024
// Chakra UI Styling was based on documentation provided on Chakra's website:  
// https://v2.chakra-ui.com/docs/components/checkbox

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Checkbox, Flex, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';
const LootItemsDropdown = ({ onChange, defaultValue }) => {
  // declare state variable for lootItems and setLootItems to update state variable. Initialize as an empty array.
  const [lootItems, setLootItems] = useState([]);

  // effect hook to fetch lootItems with additional attribute for checked and quantity
  useEffect(() => {
    const fetchLootItems = async () => {
      // fetch data from Loot Items table
      try {
        const URL = import.meta.env.VITE_API_URL + 'lootitems';
        const response = await axios.get(URL);
        // If the form was filled out previously, this will fill in the previously selected loot items and its quantity
        console.log('defaultValueLootItems:', defaultValue);
        if (defaultValue) {
          // iterate through array of previous items and update previously selected loot items objects and add checked properties (checkbox selected) and quantity from defaultItem 
          const lootItemsWithChecked = response.data.map(item => {
            const defaultItem = defaultValue.find(lootItem => lootItem.lootItemID === item.lootItemID);
            return { 
              ...item, 
              checked: defaultItem ? true : false, // if defaultItem is true, set checked property to true. Set false otherwise
              quantity: defaultItem ? defaultItem.quantity : 0, // if defaultItem is true, set quantity property to current quantity value. Set to 0 otherwise
            };
          });
          // Update state variable array 
          setLootItems(lootItemsWithChecked);
        } 
        // handles the case of not having preselected loot items (creating a trip or updating a trip that had no loot items selected previously)
        else {
          // initialize the checked property as false and quantity as 1
          const lootItemsWithChecked = response.data.map(item => ({ ...item, checked: false, quantity: 1 }));
          // Update state variable array 
          setLootItems(lootItemsWithChecked);
        }
      } catch (error) {
        console.error('Error fetching loot items:', error);
        alert('Error fetching loot items from the server.');
      }
    };

    fetchLootItems();
  }, [defaultValue]);

  // event handler for selecting a lootItem checkbox to update state of checkbox if checked or not
  const handleLootItemChange = (event, lootItem) => {
    // store boolean value of the current state of the checkbox - true = checkbox checked, false = checkbox not checked
    const isChecked = event.target.checked;
    // iterate through each lootItem in state and create a new lootItem object if lootItemID matches the lootItem checkbox selected
    // and update the checked property created previously. A ternary operator is used to only update the checkbox status for the selected
    // lootItemID/checkbox only.
    const updatedLootItems = lootItems.map(item =>
      item.lootItemID === lootItem.lootItemID ? { ...item, checked: isChecked } : item
    );
    // update lootItem state to include lootItem that was checked by the user
    setLootItems(updatedLootItems);

    // Ternary operator used to set the initial value of quantity. If the lootItem is checked, default quantity to 1. If not checked, it will default to 0.
    const quantity = isChecked ? 1 : 0;

    // call the onChange callback with the lootItem, isChecked state, and current quantity
    onChange(lootItem, isChecked, quantity);
  };

  // event handler for entering the quantity value after a lootItem is selected
  const handleQuantityChange = (event, lootItem) => {
    // reads numeric value entered by user for quantity input field and assigns to quantity variable
    const quantity = parseInt(event.target.value) || '';
    
    // iterate through each lootItem in state and create a new lootItem object as done in the previous handler. Updates
    // the quantity status for the selected lootItem. A ternary operator is used to only update the quantity status for the selected
    // lootItemID/checkbox only.
    const updatedLootItems = lootItems.map(item =>
      item.lootItemID === lootItem.lootItemID ? { ...item, quantity } : item
    );
    
    // update lootItem state to include quantity of lootItem that was entered by the user
    setLootItems(updatedLootItems);

    // passes status of lootItem checkbox to checked to onChange callback function below
    const isChecked = lootItem.checked;

    // call the onChange callback with the lootItem, isChecked state, and new quantity value
    onChange(lootItem, isChecked, quantity);
  };

  return (
    <Box>
      <Heading as="h4" size="md" mb={4}>Loot Collected</Heading>
      <Text mb={4}>Please select the loot items collected on the trip.</Text>
      {/* Iterate through each lootItem and display a checkbox for each lootItem for user to select */}
      {lootItems.map((lootItem) => (
        <Flex key={lootItem.lootItemID} mb={2} align="center">
          <Checkbox
            value={lootItem.lootItemID}
            isChecked={lootItem.checked}
            onChange={(event) => handleLootItemChange(event, lootItem)}
            colorScheme='green'
          >
            {lootItem.name}
          </Checkbox>
          {/* Display quantity input field only when the associated lootItem has been selected */}
          {lootItem.checked && (
            <FormLabel ml={2} mb={0} display="flex" alignItems="center">
              , Quantity:
              <Input
                type="number"
                min="1"
                required
                value={lootItem.quantity}
                onChange={(event) => handleQuantityChange(event, lootItem)}
                size="sm"
                width="auto"
                ml={2}
                bg="rgba(0, 0, 0, 0.80)"
              />
            </FormLabel>
          )}
        </Flex>
      ))}
    </Box>
  );
};

export default LootItemsDropdown;