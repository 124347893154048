//Citation for the following MonstersDropdown file and function:
//Date: 05/12/2024
// Using .map() and an arrow function to return a modified lootItem object from the monsters array with an additional attribute/property for being checked in 'monstersWithChecked' was inspired by: 
//Source URL: https://stackoverflow.com/a/71558196

//Date: 05/17/2024
// Implementation and handling of checkboxes was partially inspired by:  
//Source URL: https://dev.to/collegewap/how-to-work-with-checkboxes-in-react-44bc
//Source URL: https://www.freecodecamp.org/news/how-to-work-with-multiple-checkboxes-in-react/

// Citations for Chakra UI Configurations:
//Date: 05/29/2024
// Chakra UI Styling was based on documentation provided on Chakra's website:  
// https://v2.chakra-ui.com/docs/components/checkbox

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Checkbox, Flex, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

const MonstersDropdown = ({ onChange, defaultValue }) => {
  // declare state variable for monsters and setMonsters to update state variable. Initialize as an empty array.
  const [monsters, setMonsters] = useState([]);

  // effect hook to fetch monsters with additional attribute for checked and killed status
  useEffect(() => {
    const fetchMonsters = async () => {
      // fetch data from Monsters table
      try {
        const URL = import.meta.env.VITE_API_URL + 'monsters';
        const response = await axios.get(URL);
        console.log('defaultValueMonsters:', defaultValue);
        // If the form was filled out previously, this will fill in the previously selected monsters and its killed status
        if (defaultValue) {
          // iterate through array of previous monsters and update previously selected monster objects and add checked properties (checkbox selected) and killed status from defaultMonster 
          const monstersWithChecked = response.data.map(item => {
            const defaultMonster = defaultValue.find(monster => monster.monsterID === item.monsterID);
            return { 
              ...item, 
              checked: defaultMonster ? true : false, // if defaultMonster is true, set checked property to true. Set false otherwise
              killed: defaultMonster ? !!defaultMonster.killed : false  // if defaultMonster is true, set killed status property to status associated with that monster object. Set false otherwise
            };
          });
          // Update state variable array 
          setMonsters(monstersWithChecked);
        } 
        // handles the case of not having preselected monsters (creating a trip or updating a trip that had no monsters selected previously)
        else {
          const monstersWithChecked = response.data.map(item => ({ ...item, checked: false, killed: false }));
          // Update state variable array 
          setMonsters(monstersWithChecked);
        }
      } catch (error) {
        console.error('Error fetching monsters:', error);
        alert('Error fetching monsters from the server.');
      }
    };

    fetchMonsters();
  }, [defaultValue]);


 // event handler for selecting a monster checkbox to update state of checkbox if checked or not
  const handleMonsterChange = (event, monster) => {
    // store boolean value of the current state of the checkbox - true = checkbox checked, false = checkbox not checked
    const isChecked = event.target.checked;
    // iterate through each monster in state and create a new monster object if monsterID matches the monster checkbox selected
    // and update the checked property created previously. A ternary operator is used to only update the checkbox status for the selected
    // monsterID/checkbox only.
    const updatedMonsters = monsters.map(item =>
      item.monsterID === monster.monsterID ? { ...item, checked: isChecked } : item
    );
    // update monster state to include monsters that were checked by the user
    setMonsters(updatedMonsters);

    // call the onChange callback with the monster, isChecked state, and current killed status (which is initially false/unchecked in this handler)
    onChange(monster, isChecked, monster.killed);
  };

  // event handler for selecting the killed checkbox after a monster is selected
  const handleKilledChange = (event, monster) => {
    // store boolean value of the current state of the checkbox as done in the previous handler
    const killed = event.target.checked;
    // iterate through each monster in state and create a new monster object as done in the previous handler. Updates
    // the killed status for the selected monster. A ternary operator is used to only update the killed status for the selected
    // monsterID/checkbox only.
    const updatedMonsters = monsters.map(item =>
      item.monsterID === monster.monsterID ? { ...item, killed } : item
    );
    // update monster state to include killed status of monster that was checked by the user
    setMonsters(updatedMonsters);
    
    // passes status of monster checkbox to checked to onChange callback function below
    const isChecked = monster.checked;

    // call the onChange callback with the monster, isChecked state, and new killed status
    onChange(monster, isChecked, killed);
  };

  return (
    <Box>
      <Heading as="h4" size="md" mb={4}>Monster Encounters</Heading>
      <Text mb={4}>Please select the monsters encountered on the trip.</Text>
      {/* Iterate through each monster and display a checkbox for each monster for user to select */}
      {monsters.map((monster) => (
        <Flex key={monster.monsterID} mb={2} align="center">
          <Checkbox
            value={monster.monsterID}
            isChecked={monster.checked}
            onChange={(event) => handleMonsterChange(event, monster)}
            colorScheme='green'
          >
            {monster.species}
          </Checkbox>
          {/* Display killed checkbox only when the associated monster has been selected */}
          {monster.checked && (
            <FormLabel ml={2} mb={0} display="flex" alignItems="center">
              , Killed:
              <Checkbox
                isChecked={monster.killed}
                onChange={(event) => handleKilledChange(event, monster)}
                ml={2}
                colorScheme='green'
              />
            </FormLabel>
          )}
        </Flex>
      ))}
    </Box>
  );
};

export default MonstersDropdown;
