//Citation for the following TripsTableRow file and TableRow function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
const TableRow = ({ trip, fetchTrips }) => {
  // Hook that allows us to navigate programmatically
  const navigate = useNavigate();
  // Redirect to edit person page
  const handleEdit = () => {
    // We can access the id (and query the person) with useParams() in the UpdateTrip component
    navigate("/trips/edit/" + trip.tripID, { state: { trip } });
  };

  // Function handles deletion of a trip row in the table by passing the tripID to the backend
  const deleteRow = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "trips/" + trip.tripID;
      const response = await axios.delete(URL);

      // Ensure that the trip was deleted successfully
      if (response.status === 204) {
        alert("Trip deleted successfully");
      }
    } catch (err) {
      alert(err.response.data.error || "Error deleting trip");
      console.log(err);
    }
    fetchTrips();
  };
  
  console.log('trip:',trip); // debugging statement, to be removed later
  console.log('employeeeees:',trip.employee); // debugging statement, to be removed later
  return (
    <Tr  key={trip.tripID}>
      <Td>{trip.tripID}</Td>
      <Td>{trip.date.slice(0,10)}</Td> {/*Slice date to remove time from date to display*/}
      <Td>{trip.quotaMet}</Td>
      <Td>{trip.employee || "N/A"}</Td>
      <Td>{trip.moon_name}</Td>
      <Td>{trip.weather}</Td>
      <Td>{trip.loot_items}</Td>
      <Td>{trip.monsters}</Td>
      <Td>
        <BiEditAlt onClick={handleEdit} size={25} style={{ cursor: "pointer" }} />
        </Td>
      <Td>
        <BsTrash onClick={deleteRow} size={25} style={{ cursor: "pointer" }} />
        </Td>
      </Tr>
  );
};

export default TableRow;
