import { useState, useEffect } from "react";
import { RiCreativeCommonsZeroFill } from "react-icons/ri";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import TableRow from "./EmployeesTableRow";
import axios from "axios";
import { 
  Box, 
  Heading, 
  Flex, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  TableContainer, 
  Text,
  Input,
  Select,
  Stack,
  HStack,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

const EmployeesTable = () => {
  const [employees, setEmployees] = useState([]);
  const [sortBy, setSortBy] = useState('employeeID');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    firstName: '',
    lastName: '',
    rank: '',
    status: ''
  });

  // Fetch data from Employees table with sorting and filtering
  const fetchEmployees = async () => {
    try {
      // Build query parameters
      const params = new URLSearchParams({
        sortBy,
        sortOrder,
        ...Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== '')
        )
      });

      const URL = `${import.meta.env.VITE_API_URL}employees?${params}`;
      const response = await axios.get(URL);
      setEmployees(response.data);
    } catch (error) {
      alert("Error fetching employees from the server.");
      console.error("Error fetching employees:", error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [sortBy, sortOrder, filters]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Box p={4} maxWidth="1000px" mx="auto" textAlign="center">
      <Text mb={4}>
        The table below displays all employees of The Company. Click the button above to add a new employee. 
        To update or delete an employee, click the respective button in the same row as the employee.
      </Text>

      {/* Filter Controls */}
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
        <Wrap spacing={{ base: 2, md: 4 }} justify="center">
          <WrapItem>
            <Input
              placeholder="Filter by First Name"
              value={filters.firstName}
              onChange={(e) => handleFilterChange('firstName', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "auto" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Last Name"
              value={filters.lastName}
              onChange={(e) => handleFilterChange('lastName', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "auto" }}
            />
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Rank"
              value={filters.rank}
              onChange={(e) => handleFilterChange('rank', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "auto" }}
            >
              <option value="Intern">Intern</option>
              <option value="Part-Timer">Part-Timer</option>
              <option value="Employee">Employee</option>
              <option value="Leader">Leader</option>
              <option value="Boss">Boss</option>
            </Select>
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Status"
              value={filters.status}
              onChange={(e) => handleFilterChange('status', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "auto" }}
            >
              <option value="Alive">Alive</option>
              <option value="Dead">Dead</option>
            </Select>
          </WrapItem>
        </Wrap>
      </Box>

      {employees.length === 0 ? (
        <Box as="header" py={5} px={10} textAlign="center">
          <Flex align="center" justify="center" direction="column">
            <RiCreativeCommonsZeroFill size={70} color="gray" />
            <Text bg='black' px={4} py={1} mt={5}>No employees in Employees found.</Text>
          </Flex> 
        </Box>
      ) : (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {[
                  { id: 'employeeID', label: 'ID' },
                  { id: 'firstName', label: 'First Name' },
                  { id: 'lastName', label: 'Last Name' },
                  { id: 'rank', label: 'Rank' },
                  { id: 'status', label: 'Status' }
                ].map(column => (
                  <Th 
                    key={column.id}
                    onClick={() => handleSort(column.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <span>{column.label}</span>
                      {getSortIcon(column.id)}
                    </Stack>
                  </Th>
                ))}
                <Th>Edit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {employees.map((employee) => (
                <TableRow 
                  key={employee.employeeID} 
                  employee={employee} 
                  fetchEmployees={fetchEmployees} 
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};


export default EmployeesTable;