//Citation for the following UpdateTripsHasLootItems file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";

const UpdateTripsHasLootItems = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevTripsHasLootItems = location.state.tripshaslootitems;

  const [formData, setFormData] = useState({
    tripID: prevTripsHasLootItems.tripID || '',
    lootItemID: prevTripsHasLootItems.lootItemID || '',
    quantity: prevTripsHasLootItems.quantity || '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function isUpdate(){
    // Check if formData is equal to prevTripsHasLootItems
    if (JSON.stringify(formData) === JSON.stringify({
      tripID: prevTripsHasLootItems.tripID || '',
      lootItemID: prevTripsHasLootItems.lootItemID || '',
      quantity: prevTripsHasLootItems.quantity || '',
    })) {
      alert("No changes made.");
      return false;
    }
    return true
  }

  const handleSubmit = async (event) => {
    // Stop default form behavior which is to reload the page
    event.preventDefault();
    // Check if formData is equal to prevMonster
    if (isUpdate()){
      try {
        const URL = import.meta.env.VITE_API_URL + "tripshaslootitems/" + id;
        const response = await axios.put(URL, formData);
        if (response.status !== 200) {
          alert("Error updating tripshaslootitems");
        } else {
          alert(response.data.message);
          // Redirect to tripshaslootitems page
          navigate("/tripshaslootitems");
        }
      } catch (err) {
        console.log("Error updating tripshaslootitems:", err);
      }
    }
  };

  return (
    <div>
      <h2>Update TripsHasLootItems</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>tripID</label>
          <input
            type="text"
            name="tripID"
            onChange={handleInputChange}
            required
            defaultValue={prevTripsHasLootItems.tripID}
          />
        </div>
        <div>
          <label>Loot Item ID</label>
          <input
            type="text"
            name="lootItemID"
            onChange={handleInputChange}
            required
            defaultValue={prevTripsHasLootItems.lootItemID}
          />
        </div>
        <div>
          <label>Quantity</label>
          <input
            type="text"
            name="quantity"
            onChange={handleInputChange}
            defaultValue={prevTripsHasLootItems.quantity}
          />
        </div>
        <button type="button" onClick={() => navigate("/tripshaslootitems")}>
          Cancel
        </button>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default UpdateTripsHasLootItems;

