//Citation for the following EmployeesPage file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import { Routes, Route, Link } from "react-router-dom";
import CreateEmployee from "../components/Employees/CreateEmployee";
import EmployeesTable from "../components/Employees/EmployeesTable";
import UpdateEmployee from "../components/Employees/UpdateEmployee";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Text} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { BiPlus, BiTable } from "react-icons/bi";

function EmployeesPage() {
  const location = useLocation();
  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={4}>Employees</Heading>
      <Flex as="nav" justify="center" mb={4}>
      {(location.pathname == "/employees/add" || location.pathname.startsWith( "/employees/edit/"))  && (
        <Button as={Link} to="/employees" mr={2} leftIcon={<BiTable />} _hover={{ bg: "#7cfc7c" }}>
          Employees Table
        </Button>
      )}
      
      {location.pathname === "/employees" && (<Button as={Link} to="/employees/add" mr={2} leftIcon={<BiPlus />} _hover={{ bg: "#7cfc7c" }}>
          Add Employee
        </Button>
      )}
      </Flex>
      <Routes>
        <Route path="/" element={<EmployeesTable />} />
        <Route path="/add" element={<CreateEmployee />} />
        <Route path="/edit/:id" element={<UpdateEmployee />} />
      </Routes>
    </Box>
  );
}

export default EmployeesPage;