//Citation for the following UpdateTrip file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Date: 05/18/2024
// Use of .map() and .filter() functions in this file were inspired and adapted from:
// Source URL: https://medium.com/poka-techblog/simplify-your-javascript-use-map-reduce-and-filter-bd02c593cc2d 

//Date: 05/17/2024
// Implementation and handling of checkboxes was partially inspired by:  
//Source URL: https://dev.to/collegewap/how-to-work-with-checkboxes-in-react-44bc
//Source URL: https://www.freecodecamp.org/news/how-to-work-with-multiple-checkboxes-in-react/

//Date: 05/17/2024
// Removing previously checked items contained in state arrays for handleLootItemsSelect and handleMonstersSelect is inspired from:
// Source URL: https://stackoverflow.com/a/61986943

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

//Date: 05/30/2024
// CSS Color Filter for Date Calendar Symbol
//Source URL: https://stackoverflow.com/a/62162805



import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import MoonsDropdown from '../Dropdowns/moonsDropdown/';
import EmployeesDropdown from '../Dropdowns/employeesDropdown/';
import LootItemsDropdown from '../Dropdowns/lootItemsDropdown/';
import MonstersDropdown from '../Dropdowns/monstersDropdown/';
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

const UpdateTrip = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevTrip = location.state.trip;

  // Special mapping needed since the user selects "No" or "Yes" on the form, and this needs to be mapped to TINYINT values used in backend
  const quotaMetMapping = {
    "No": 0,
    "Yes": 1
  };

  // Split employee into firstName and lastName attributes since it's passed in as 'firstName lastName'
  console.log('prevTrip.employee:',prevTrip.employee); // debugging statement, delete later
  // Need to check if prevTrip.employee is NULL when updating a trip that previously had employee as NULL. If not NULL, split employee into first and last name, otherwise set to empty string 
  const [firstName, lastName] = prevTrip.employee ? prevTrip.employee.split(' ') : ['', ''];

  const [formData, setFormData] = useState({
    date: prevTrip.date.slice(0, 10) || '', // remove timestamp info from date 
    quotaMet: quotaMetMapping[prevTrip.quotaMet] === 0 ? '0' : '1', // use quotaMet mapping to convert Yes/No from frotend into 1/0 for backend
    weather: prevTrip.weather || 'clear', // defaults to clear if no value found from previous data to prevent no weather passing into backend 
    moon_name: prevTrip.moon_name || '',
    employee_name: `${firstName} ${lastName}` || '', // stores employee firstName lastName combination as a template literal
    employeeID: prevTrip.employeeID || '', // stores employee ID of selected employee 
    lootItems: [] || '', // array to hold the selected loot items. Initialized as an empty array that will be updated in the effect hook
    monsters: [] || '' // array to hold the selected monsters. Initialized as an empty array that will be updated in the effect hook
  });
  
  useEffect(() => {
    // Function fetches data from tables to form loot items list selection menu
    const fetchTripLootItems = async () => {
      try {
        // Fetch data from TripsHasLootItems table and store respnonse in tripLootItems
        const tripLootItemsURL = import.meta.env.VITE_API_URL + 'tripshaslootitems/update';
        const tripLootItemsResponse = await axios.get(tripLootItemsURL);
        const tripLootItems = tripLootItemsResponse.data;
  
        // Fetch data from LootItems table and store response in lootItems
        const lootItemsURL = import.meta.env.VITE_API_URL + 'lootitems';
        const lootItemsResponse = await axios.get(lootItemsURL);
        const lootItems = lootItemsResponse.data;
  
        // Data from TripsHasLootItems currently displays all entries in this table from every trip. This filters that data to only show the rows that have a matching tripID
        const filteredTripLootItems = tripLootItems.filter(item => item.tripID === parseInt(id));
        
        console.log(filteredTripLootItems);
        // Data from the filtered TripsHasLootItems table and LootItems table needs to be merged so that quantity from TripsHasLootItems table can be added into the respective lootItems objects
        const combinedLootItems = filteredTripLootItems.map(tripLootItem => {
          console.log('tripLootItem:', tripLootItem); // debugging statement, to be deleted later
          const lootItem = lootItems.find(item => item.lootItemID === tripLootItem.lootItemID);
          console.log('lootItem:',lootItem); // debugging statement, to be deleted later
            return {
              ...lootItem,
              quantity: tripLootItem.quantity
            };
        })
        console.log(combinedLootItems);
        // Update the form data state with latest lootItems array
        setFormData(prevFormData => ({
          ...prevFormData,
          lootItems: combinedLootItems,
        }));
  
      } catch (error) {
        console.error('Error fetching loot items data:', error);
      }
    };
  

    // Function fetches data from tables to form monsters list selection menu
    const fetchTripMonsters = async () => {
      try {
        // Fetch data from TripsHasMonsters table and store respnonse in tripMonsters
        const tripMonstersURL = import.meta.env.VITE_API_URL + 'tripshasmonsters/update';
        const tripMonstersResponse = await axios.get(tripMonstersURL);
        const tripMonsters = tripMonstersResponse.data;
      
        // Fetch data from Monsters table and store response in monsters
        const monstersURL = import.meta.env.VITE_API_URL + 'monsters';
        const monstersResponse = await axios.get(monstersURL);
        const monsters = monstersResponse.data;
      
        // Data from TripsHasMonsters currently displays all entries in this table from every trip. This filters that data to only show the rows that have a matching tripID
         const filteredTripMonsters = tripMonsters.filter(item => item.tripID === parseInt(id));
      
        // Data from the filtered TripsHasMonsters table and monsters table needs to be merged so that killed status from TripsHasMonsters table can be added into the respective monsters objects
        const combinedMonsters = filteredTripMonsters.map(tripMonster => {
          const monster = monsters.find(item => item.monsterID === tripMonster.monsterID);
            return {
              ...monster,
              killed: tripMonster.killed
            };
          })
        // Set the combined data to formData
        setFormData(prevFormData => ({
          ...prevFormData,
          monsters: combinedMonsters,
        }));
      
      } catch (error) {
        console.error('Error fetching monsters data:', error);
      }
    };

    fetchTripLootItems();
    fetchTripMonsters();
  }, [id]); //Effect hook rerenders when trip id is changed

  // Effect hook that rerenders when formData state changes. Used for debugging purposes only
  useEffect(() => {
    console.log('prevTrip:', prevTrip);
    console.log('Initial formData:', formData);
  }, [formData]);


  // Handler for when the input fields for date, quotaMet and weather is updated
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

    // Checks if updated form data is same as previous form data and doesn't perform any updates if so
    function isUpdate(){
      // Check if formData is equal to prevMoon
      if (JSON.stringify(formData) === JSON.stringify({
        date: prevTrip.date.slice(0, 10) || '',
        quotaMet: quotaMetMapping[prevTrip.quotaMet] === 0 ? '0' : '1',
        weather: prevTrip.weather || 'clear',
        moon_name: prevTrip.moon_name || '',
        employee_name: `${firstName} ${lastName}` || '',
        employeeID: prevTrip.employeeID || '',
        lootItems: [] || '',
        monsters: [] || ''
      })) {
        alert("No changes made.");
        return false;
      }
      return true
    }

  // Handler for when the trips form is submitted
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isUpdate()){
      try {
        const URL = import.meta.env.VITE_API_URL + "trips/" + id;
        const response = await axios.put(URL, formData);
        if (response.status !== 200) {
          alert("Error updating trip");
        } else {
          alert(response.data.message);
          // Redirect to trips page
          navigate("/trips");
        }
      } catch (err) {
        console.log("Error updating trip:", err);
    }
  }
  };

  // Handler for selecting a moon from the dropdown list. Adds the selected moonID to the formData to be passed to backend.
  const handleMoonSelect = (selectedMoon) => {
    setFormData((prevData) => ({
      ...prevData,
      moonID: selectedMoon ? selectedMoon.moonID : "" // if a moon has been selected, set moonID for FormData state, otherwise set as empty string
    }));
  };

  // Handler for selecting an employee from the dropdown list. Adds the selected employeeID to the formData to be passed to backend.
  const handleEmployeeSelect = (selectedEmployee) => {
    setFormData((prevData) => ({
      ...prevData,
      employee_name: selectedEmployee ? `${selectedEmployee.firstName} ${selectedEmployee.lastName}` : '', // Add employee first and last name for FormData
      employeeID: selectedEmployee ? selectedEmployee.employeeID : "" // if an employee has been selected, set employeeID for FormData state
    }));
  };

  // Handler for selecting loot items to add to a trip. Adds loot item and quantity to be passed to backend.
  const handleLootItemsSelect = (selectedLootItem, isChecked, quantity) => {
    setFormData((prevData) => {
      // Find the index of the selected loot item in the current lootItems array
      const existingItemIndex = prevData.lootItems.findIndex(item => item.lootItemID === selectedLootItem.lootItemID);

      // Initialize lootItems array
      let lootItems;

      // Check if the checkbox for a loot item was selected
      if (isChecked) {
        // If the lootItem is already in the array (findIndex did not return -1), update its quantity
        if (existingItemIndex !== -1) {
          // Iterate through each lootItem in prevData.lootItems and create a new lootItems array. For each loot item, check if list index matches existingItemIndex.
          // If a match is found, create a copy of the loot item and add the quantity attribute to it. Otherwise, leave item the same with no modifications.
          lootItems = prevData.lootItems.map((item, index) =>
            index === existingItemIndex ? { ...item, quantity } : item
          );
          console.log('Look I ran');
        } else {
          // If the lootItem is not already in the lootItems array, add additional lootItem object to array and pass lootItemID and quantity to it
          lootItems = [...prevData.lootItems, { lootItemID: selectedLootItem.lootItemID, name: selectedLootItem.name, quantity }];
        }
      } else {
        // If checkbox not selected for a lootItem, remove it from lootItems list.
        lootItems = prevData.lootItems.filter(item => item.lootItemID !== selectedLootItem.lootItemID);
      }

      console.log('Finalized lootItems:', lootItems); // debugging log, to be deleted later

      // Return updated state
      return { ...prevData, lootItems };
    });
  };

  // Handler for selecting monsters to add to a trip. Adds monsters and killed status to be passed to backend.
  const handleMonstersSelect = (selectedMonster, isChecked, killed) => {
    setFormData((prevData) => {
      console.log('monsters:', prevData.monsters)
      const existingMonsterIndex = prevData.monsters.findIndex(monster => monster.monsterID === selectedMonster.monsterID);
      let monsters;
       // Check if the checkbox for a monster was selected
      if (isChecked) {
         // If the monster is already in the array (findIndex did not return -1), update its killed status
        if (existingMonsterIndex !== -1) {
          // Iterate through each monster in prevData.monsters and create a new monsters array. For each monster, check if array index matches existingItemIndex.
          // If a match is found, create a copy of the monster and add the killed status attribute to it. Otherwise, leave item the same with no modifications.
          monsters = prevData.monsters.map((monster, index) =>
            index === existingMonsterIndex ? { ...monster, killed } : monster
          );
        } 
        // if the monster is not already in the monsters array, add additional monster object to array and pass monsterID and killed status to it
        else {
          monsters = [...prevData.monsters, { monsterID: selectedMonster.monsterID, species: selectedMonster.species, killed }];
        }
      } 
      // If checkbox not selected for a monster, remove it form monsters list.
      else {
        monsters = prevData.monsters.filter(monster => monster.monsterID !== selectedMonster.monsterID);
      }

      // Return updated state
      return { ...prevData, monsters };
    });
  };

  return (
    <Box p={4}>
      <Heading as="h2" size="lg" mb={4}>Update Trip</Heading>
      <form onSubmit={handleSubmit}>

        {/*Trip date Input field on form */}
        <FormControl mb={4}>
          <FormLabel>Date</FormLabel>
          <Input
            type="date"
            name="date"
            onChange={handleInputChange}
            value={formData.date}
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Date' 
            css={{
              '&::-webkit-calendar-picker-indicator': {
                filter: 'invert(1)',
              },
            }}
          />
        </FormControl>

        {/*Quota Met dropdown field on form */}
        <FormControl mb={4}>
          <FormLabel htmlFor="quotaMet">Quota Met</FormLabel>
          <Select name="quotaMet" 
            value={formData.quotaMet} 
            onChange={handleInputChange}
            required
            placeholder='Select Quota Met Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Select>
        </FormControl>

        {/*Weather dropdown field on form */}
        <FormControl mb={4}>
          <FormLabel htmlFor="weather">Weather</FormLabel>
          <Select name="weather" 
            value={formData.weather} 
            onChange={handleInputChange}
            required
            placeholder='Select Weather Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'>
            <option value="clear">clear</option>
            <option value="rainy">rainy</option>
            <option value="stormy">stormy</option>
            <option value="foggy">foggy</option>
            <option value="flooded">flooded</option>
            <option value="eclipsed">eclipsed</option>
          </Select>
        </FormControl>

        {/*Use MoonsDropdown component to display moons stored in Moons table in dropdown list */}
        <FormControl mb={4}>
          <FormLabel htmlFor="moonID">Moon</FormLabel>
          <MoonsDropdown onChange={handleMoonSelect} defaultValue={formData.moon_name} />
        </FormControl>

        {/*Use EmployeesDropdown component to display employees stored in Employees table in dropdown list */}
        <FormControl mb={8}>
          <FormLabel htmlFor="employeeID">Employee</FormLabel>
          <EmployeesDropdown onChange={handleEmployeeSelect} defaultValue={formData.employee_name} /> 
        </FormControl>

        {/*Use LootItemsDropdown component to display loot items as a list of selection options  */}
        <FormControl mb={6}>
          <FormLabel htmlFor="lootItems"></FormLabel>
          <LootItemsDropdown onChange={handleLootItemsSelect} defaultValue={formData.lootItems} /> 
        </FormControl>

        {/*Use MonstersDropdown component to display monsters as a list of selection options  */}
        <FormControl mb={4}>
          <FormLabel htmlFor="monsters"></FormLabel>
          <MonstersDropdown monsters onChange={handleMonstersSelect} defaultValue={formData.monsters} /> 
        </FormControl>

        <Button type="button" _hover={{ bg: "#7cfc7c" }} onClick={() => navigate("/trips")} mr={4}>
          Cancel
        </Button>
        <Button type="submit" _hover={{ bg: "#7cfc7c" }}>Update</Button>
      </form>
    </Box>
  );
};

export default UpdateTrip;
