import { useState, useEffect } from "react";
import { RiCreativeCommonsZeroFill } from "react-icons/ri";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import TableRow from "./TripsHasLootItemsTableRow";
import axios from "axios";
import { 
  Box, 
  Heading, 
  Flex, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  TableContainer, 
  Text,
  Input,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

const TripsHasLootItemsTable = () => {
  const [tripsHasLootItems, setTripsHasLootItems] = useState([]);
  const [sortBy, setSortBy] = useState('tripLootItemID');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    trip: '',
    name: '',
    quantity: '',
    moonName: '',
    date: ''
  });

  const fetchTripsHasLootItems = async () => {
    try {
      // Build query parameters
      const params = new URLSearchParams({
        sortBy,
        sortOrder,
        ...Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== '')
        )
      });

      const URL = `${import.meta.env.VITE_API_URL}tripshaslootitems?${params}`;
      const response = await axios.get(URL);
      setTripsHasLootItems(response.data);
    } catch (error) {
      alert("Error fetching trip loot items from the server.");
      console.error("Error fetching trip loot items:", error);
    }
  };

  useEffect(() => {
    fetchTripsHasLootItems();
  }, [sortBy, sortOrder, filters]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Box p={4} maxWidth="1200px" mx="auto" textAlign="center">
      <Text mb={4}>
        The table below displays all loot items collected during trips.
      </Text>
  
      {/* Filter Controls */}
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
        <Wrap spacing={{ base: 2, md: 4 }} justify="center">
          <WrapItem>
            <Input
              placeholder="Filter by Trip Info"
              value={filters.trip}
              onChange={(e) => handleFilterChange('trip', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Moon"
              value={filters.moonName}
              onChange={(e) => handleFilterChange('moonName', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              type="date"
              value={filters.date}
              onChange={(e) => handleFilterChange('date', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Item Name"
              value={filters.name}
              onChange={(e) => handleFilterChange('name', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <NumberInput
              min={0}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
              value={filters.quantity}
              onChange={(valueString) => handleFilterChange('quantity', valueString)}
            >
              <NumberInputField placeholder="Filter by Quantity" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </WrapItem>
        </Wrap>
      </Box>
  
      {tripsHasLootItems.length === 0 ? (
        <Box as="header" py={5} px={10} textAlign="center">
          <Flex align="center" justify="center" direction="column">
            <RiCreativeCommonsZeroFill size={70} color="gray" />
            <Text bg='black' px={4} py={1} mt={5}>No trip loot items found matching the current filters.</Text>
          </Flex> 
        </Box>
      ) : (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {[
                  { id: 'tripLootItemID', label: 'ID' },
                  { id: 'trip', label: 'Trip Info' },
                  { id: 'name', label: 'Item Name' },
                  { id: 'quantity', label: 'Quantity' }
                ].map(column => (
                  <Th 
                    key={column.id}
                    onClick={() => handleSort(column.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <span>{column.label}</span>
                      {getSortIcon(column.id)}
                    </Stack>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {tripsHasLootItems.map((item) => (
                <TableRow 
                  key={item.tripLootItemID} 
                  tripshaslootitems={item}
                  fetchTripsHasLootItems={fetchTripsHasLootItems}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TripsHasLootItemsTable;