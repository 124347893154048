//Citation for the following CreateTripsHasLootItems file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function CreateTripsHasLootItems() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    tripID: "",
    lootItemID: "",
    quantity: ""
  });
  
  const handleSubmit = async (e) => {
    // Prevent page reload
    e.preventDefault();
    // Create a new tripLootItem object from the formData
    const CreateTripsHasLootItems = {
      tripID: formData.tripID,
      lootItemID: formData.lootItemID,
      quantity: formData.quantity
    };

    try {
      const URL = import.meta.env.VITE_API_URL + "tripshaslootitems";
      const response = await axios.post(URL, newtripshaslootitems);
      if (response.status === 201) {
        navigate("/tripshaslootitems");
      } else {
        alert("Error creating tripshaslootitems");
      }
    } catch (error) {
      alert("Error creating tripshaslootitems");
      console.error("Error creating tripshaslootitems:", error);
    }
    // Reset the form fields
    resetFormFields();
  };

  const resetFormFields = () => {
    setFormData({
      tripID: "",
      lootItemID: "",
      quantity: ""
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <h2>Create TripsHasLootItems</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="tripID">tripID</label>
        <input
          type="text"
          name="tripID"
          defaultValue={formData.tripID}
          onChange={handleInputChange}
        />
        <label htmlFor="lootItemID">lootItemID</label>
        <input
          type="text"
          name="lootItemID"
          defaultValue={formData.lootItemID}
          onChange={handleInputChange}
        />
        <label htmlFor="quantity">Quantity</label>
        <input
          type="text"
          name="quantity"
          value={formData.quantity}
          onChange={handleInputChange}
        />
        <button type="submit">Create TripsHasLootItems</button>
      </form>
    </>
  );
}

export default CreateTripsHasLootItems;
