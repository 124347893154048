//Citation for the following UpdateEmployee file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citation for updating placeholder text color:
// Date: 05/30/2024
// Adapted from the following URL:
// Source URL: https://github.com/chakra-ui/chakra-ui/discussions/3110#discussioncomment-297903

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

const UpdateEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevEmployee = location.state.employee;

  // holds state variable for form data and its set function. Data in this state variable is sent to the backend when form is submitted
  const [formData, setFormData] = useState({
    firstName: prevEmployee.firstName || '',
    lastName: prevEmployee.lastName || '',
    rank: prevEmployee.rank || '',
    status: prevEmployee.status === 0 ? '0' : '1',
  });

  // Handler for when the input fields for firstName, lastName, rank and status are updated
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Checks if updated form data is same as previous form data and doesn't perform any updates if so
  function isUpdate(){
    // Check if formData is equal to prevEmployee
    if (JSON.stringify(formData) === JSON.stringify({
      firstName: prevEmployee.firstName || '',
      lastName: prevEmployee.lastName || '',
      rank: prevEmployee.rank || '',
      status: prevEmployee.status || '',
    })) {
      alert("No changes made.");
      return false;
    }
    return true
  }

  // Handler for when the Employees form is submitted
  const handleSubmit = async (event) => {
    // Stop default form behavior which is to reload the page
    event.preventDefault();
    // Check if formData is equal to prevEmployee
    if (isUpdate()){
      try {
        const URL = import.meta.env.VITE_API_URL + "employees/" + id;
        const response = await axios.put(URL, formData);
        if (response.status !== 200) {
          alert("Error updating employee");
        } else {
          alert(response.data.message);
          // Redirect to employees page
          navigate("/employees");
        }
      } catch (err) {
        console.log("Error updating employee:", err);
      }
    }
  };

  return (
    <Box p={4}>
      <Heading as="h2" size="md" mb={4}>Update Employee</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        
        {/* Employee firstName Input field on form */}
        <FormControl id="firstName" isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="firstName"
            defaultValue={prevEmployee.firstName}
            onChange={handleInputChange}
            bg="rgba(0, 0, 0, 0.80)"
            required
            placeholder='Enter First Name'
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        {/* Employee lastName Input field on form */}
        <FormControl id="lastName" isRequired mt={4}>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="lastName"
            defaultValue={prevEmployee.lastName}
            onChange={handleInputChange}
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Last Name'
            _placeholder={{ color: '#00ff00' }}
            required
          />
        </FormControl>

        {/* Employee rank select dropdown field on form */}
        <FormControl id="rank" isRequired mt={4}>
          <FormLabel>Rank</FormLabel>
          <Select name="rank" value={formData.rank} onChange={handleInputChange} required bg="rgba(0, 0, 0, 0.80)" variant='filled'>
            <option value="Intern">Intern</option>
            <option value="Part-Timer">Part-Timer</option>
            <option value="Employee">Employee</option>
            <option value="Leader">Leader</option>
            <option value="Boss">Boss</option>
          </Select>
        </FormControl>

        {/* Employee status select dropdown field on form */}
        <FormControl id="status" isRequired mt={4}>
          <FormLabel>Status</FormLabel>
          <Select name="status" value={formData.status} onChange={handleInputChange} required bg="rgba(0, 0, 0, 0.80)" variant='filled'>
            <option value="0">Dead</option>
            <option value="1">Alive</option>
          </Select>
        </FormControl>

        <Box mt={4} display="flex" justifyContent="space-between">
          <Button onClick={() => navigate("/employees")} _hover={{ bg: "#7cfc7c" }}>
            Cancel
          </Button>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
}


export default UpdateEmployee;



