//Citation for the following UpdateTripsHasMonsters file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";

const UpdateTripsHasMonsters = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevTripsHasMonsters = location.state.tripshasmonsters;

  const [formData, setFormData] = useState({
    tripID: prevTripsHasMonsters.tripID || '',
    monsterID: prevTripsHasMonsters.monsterID || '',
    killed: prevTripsHasMonsters.killed || '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function isUpdate(){
    // Check if formData is equal to prevMonster
    if (JSON.stringify(formData) === JSON.stringify({
      tripID: prevTripsHasMonsters.tripID || '',
      monsterID: prevTripsHasMonsters.monsterID || '',
      killed: prevTripsHasMonsters.killed || '',
    })) {
      alert("No changes made.");
      return false;
    }
    return true
  }

  const handleSubmit = async (event) => {
    // Stop default form behavior which is to reload the page
    event.preventDefault();
    // Check if formData is equal to prevMonster
    if (isUpdate()){
      try {
        const URL = import.meta.env.VITE_API_URL + "tripshasmonsters/" + id;
        const response = await axios.put(URL, formData);
        if (response.status !== 200) {
          alert("Error updating tripshasmonsters");
        } else {
          alert(response.data.message);
          // Redirect to tripshasmonsters page
          navigate("/tripshasmonsters");
        }
      } catch (err) {
        console.log("Error updating tripshasmonsters:", err);
      }
    }
  };

  return (
    <div>
      <h2>Update TripsHasMonsters</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>tripID</label>
          <input
            type="text"
            name="tripID"
            onChange={handleInputChange}
            required
            defaultValue={prevTripsHasMonsters.tripID}
          />
        </div>
        <div>
          <label>MonsterID</label>
          <input
            type="text"
            name="monsterID"
            onChange={handleInputChange}
            required
            defaultValue={prevTripsHasMonsters.monsterID}
          />
        </div>
        <div>
          <label>Killed</label>
          <input
            type="text"
            name="killed"
            onChange={handleInputChange}
            defaultValue={prevTripsHasMonsters.killed}
          />
        </div>
        <button type="button" onClick={() => navigate("/tripshasmonsters")}>
          Cancel
        </button>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default UpdateTripsHasMonsters;

