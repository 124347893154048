//Citation for the following CreateTrip file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

//Date: 05/18/2024
// Code for finding existing index of an item in the loot items array was inspired by this example:
//Source URL: https://stackoverflow.com/a/38364482

//Date: 05/17/2024
// Implementation and handling of checkboxes was partially inspired by:  
//Source URL: https://dev.to/collegewap/how-to-work-with-checkboxes-in-react-44bc
//Source URL: https://www.freecodecamp.org/news/how-to-work-with-multiple-checkboxes-in-react/

//Date: 05/17/2024
// Removing previously checked items contained in state arrays for handleLootItemsSelect and handleMonstersSelect is inspired from:
// Source URL: https://stackoverflow.com/a/61986943

//Date: 05/30/2024
// CSS Color Filter for Date Calendar Symbol
//Source URL: https://stackoverflow.com/a/62162805

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MoonsDropdown from '../Dropdowns/moonsDropdown/';
import EmployeesDropdown from '../Dropdowns/employeesDropdown/';
import LootItemsDropdown from '../Dropdowns/lootItemsDropdown/';
import MonstersDropdown from '../Dropdowns/monstersDropdown/';
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

function CreateTrip() {
  const navigate = useNavigate();

  // formData state variable that holds data to send to backend when form is submitted
  const [formData, setFormData] = useState({
    date: "",
    quotaMet: "",
    weather: "",
    moonID: "",
    employeeID: "",
    lootItems: [], // Array to hold selected loot items with quantities
    monsters: [] // Array to hold monsters and status
  });

  const handleSubmit = async (e) => {
    // Prevent page reload
    e.preventDefault();
    // Create a new trip object from the formData
    const newTrip = {
      date: formData.date,
      quotaMet: formData.quotaMet,
      weather: formData.weather,
      moonID: formData.moonID,
      employeeID: formData.employeeID,
      lootItems: formData.lootItems,
      monsters: formData.monsters
    };

    console.log("Submitting new trip:", newTrip); // debugging statement, to be removed later

    try {
      const URL = import.meta.env.VITE_API_URL + "trips";
      const response = await axios.post(URL, newTrip);
      if (response.status === 201) {
        navigate("/trips");
        alert("Successfully created trip");
      } else {
        alert("Error creating trip");
      }
    } catch (error) {
      alert("Error creating trip");
      console.error("Error creating trip:", error);
    }
    // Reset the form fields
    resetFormFields();
  };

  // reset form state variable when form is reset
  const resetFormFields = () => {
    setFormData({
      date: "",
      quotaMet: "",
      weather: "clear",
      moonID: "",
      employeeID: "",
      lootItems: [],
      monsters: []
    });
  };

  // Handler for when the input fields for data, quotaMet and weather is updated
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handler for selecting a moon from the dropdown list. Adds the selected moonID to the formData to be passed to backend.
  const handleMoonSelect = (selectedMoon) => {
    setFormData((prevData) => ({
      ...prevData,
      moonID: selectedMoon ? selectedMoon.moonID : "" // if a moon has been selected, set moonID for FormData state
    }));
  };

  // Handler for selecting an employee from the dropdown list. Adds the selected employeeID to the formData to be passed to backend.
  const handleEmployeeSelect = (selectedEmployee) => {
    setFormData((prevData) => ({
      ...prevData,
      employeeID: selectedEmployee ? selectedEmployee.employeeID : null // if an employee has been selected, set employeeID for FormData state
    }));
  };

  // Handler for selecting loot items to add to a trip.  Adds loot item and quantity to be passed to backend.
  const handleLootItemsSelect = (selectedLootItem, isChecked, quantity) => {
    setFormData((prevData) => {
      // Find the index of the selected loot item in the current lootItems array 
      const existingItemIndex = prevData.lootItems.findIndex(item => item.lootItemID === selectedLootItem.lootItemID);

      // Initialize lootItems array
      let lootItems;

      // Check if the checkbox for a loot item was selected
      if (isChecked) {
        // If the lootItem is already in the array (findIndex did not return -1), update its quantity
        if (existingItemIndex !== -1) {
      // Iterate through each lootItem in prevData.lootItems and create a new lootItems array. For each loot item, check if list index matches existingItemIndex.  
      // If a match is found, create a copy of the loot item and add the quantity attribute to it. Otherwise, leave item the same with no modifications.
          lootItems = prevData.lootItems.map((item, index) => 
            index === existingItemIndex ? { ...item, quantity } : item
          );
        } 
        // if the lootItem is not already in the lootItems array, add additional lootItem object to array and pass lootItemID and quantity to it
        else {
          lootItems = [...prevData.lootItems, { lootItemID: selectedLootItem.lootItemID, name: selectedLootItem.name, quantity }];
        }
      } 
      // If checkbox not selected for a lootItem, remove it form lootItems list.
      else {
        lootItems = prevData.lootItems.filter(item => item.lootItemID !== selectedLootItem.lootItemID);
      }

      // Return updated state
      return { ...prevData, lootItems };
    });
  };

  // Handler for selecting monsters to add to a trip.  Adds monsters and killed status to be passed to backend.
  const handleMonstersSelect = (selectedMonster, isChecked, killed) => {
    setFormData((prevData) => {
      // Find the index of the selected monster in the current monsters array 
      const existingMonsterIndex = prevData.monsters.findIndex(monster => monster.monsterID === selectedMonster.monsterID);
      let monsters;
  
      // Check if the checkbox for a monster was selected
      if (isChecked) {
        // If the monster is already in the array (findIndex did not return -1), update its killed status
        if (existingMonsterIndex !== -1) {
          // Iterate through each monster in prevData.monsters and create a new monsters array. For each monster, check if array index matches existingItemIndex.  
          // If a match is found, create a copy of the monster and add the kiled status attribute to it. Otherwise, leave item the same with no modifications.
          monsters = prevData.monsters.map((monster, index) => 
            index === existingMonsterIndex ? { ...monster, killed } : monster
          );
        } 
        // if the monster is not already in the monsters array, add additional monster object to array and pass mosterID and killed status to it
        else {
          monsters = [...prevData.monsters, { monsterID: selectedMonster.monsterID, species: selectedMonster.species, killed }];
        }
        // If checkbox not selected for a monster, remove it form monsters list.
      } else {
        monsters = prevData.monsters.filter(monster => monster.monsterID !== selectedMonster.monsterID);
      }
      // Return updated state
      return { ...prevData, monsters };
    });
  };
  

  return (
    <Box>
      <Heading as="h2" size="md" mb={4}>Create Trip</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        
        {/*Trip date Input field on form */}
        <FormControl isRequired mb={4}>
          <FormLabel htmlFor="date">Date</FormLabel>
          <Input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Date' 
            css={{
              '&::-webkit-calendar-picker-indicator': {
                filter: 'invert(1)',
              },
            }}
          />
        </FormControl>

        {/*Quota Met dropdown field on form */}
        <FormControl isRequired mb={4}>
          <FormLabel htmlFor="quotaMet">Quota Met</FormLabel>
          <Select name="quotaMet" 
            value={formData.quotaMet} 
            onChange={handleInputChange} 
            required
            placeholder='Select Quota Met Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Select>
        </FormControl>

        {/*Weather dropdown field on form */}
        <FormControl isRequired mb={4}>
          <FormLabel htmlFor="weather">Weather</FormLabel>
          <Select name="weather" 
            value={formData.weather} 
            onChange={handleInputChange}
            required
            placeholder='Select Weather Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'>
            <option value="clear">clear</option>
            <option value="rainy">rainy</option>
            <option value="stormy">stormy</option>
            <option value="foggy">foggy</option>
            <option value="flooded">flooded</option>
            <option value="eclipsed">eclipsed</option>
          </Select>
        </FormControl>

        {/*Use MoonsDropdown component to display moons stored in Moons table in dropdown list */}
        <FormControl isRequired mb={4}>
          <FormLabel htmlFor="moonID">Moon</FormLabel>
          <MoonsDropdown onChange={handleMoonSelect} />
        </FormControl>

        {/*Use EmployeesDropdown component to display employees stored in Employees table in dropdown list */}
        <FormControl isRequired mb={8}>
          <FormLabel htmlFor="employeeID">Employee</FormLabel>
          <EmployeesDropdown onChange={handleEmployeeSelect} />
        </FormControl>


        {/*Use LootItemsDropdown component to display loot items as a list of selection options  */}
        <FormControl mb={6}>
          <FormLabel htmlFor="lootItems"></FormLabel>
          <LootItemsDropdown onChange={handleLootItemsSelect} />
        </FormControl>


        {/*Use MonstersDropdown component to display monsters as a list of selection options  */}
        <FormControl mb={6}>
          <FormLabel htmlFor="monsters"></FormLabel>
          <MonstersDropdown onChange={handleMonstersSelect} />
        </FormControl>

        <Button type="submit" _hover={{ bg: "#7cfc7c" }}>Create Trip</Button>
      </Box>
    </Box>
  );
};

export default CreateTrip;