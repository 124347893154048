//Citation for the following TripsHasLootItemsPage file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import { Routes, Route, Link } from "react-router-dom";
import CreateTripsHasLootItems from "../components/TripsHasLootItems/CreateTripsHasLootItems";
import TripsHasLootItemsTable from "../components/TripsHasLootItems/TripsHasLootItemsTable";
import UpdateTripsHasLootItems from "../components/TripsHasLootItems/UpdateTripsHasLootItems";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";
function TripsHasLootItemsPage() {
  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={4}>Collected Loot</Heading>
      <Routes>
        <Route path="/" element={<TripsHasLootItemsTable />} />
        <Route path="/add" element={<CreateTripsHasLootItems />} />
        <Route path="/edit/:id" element={<UpdateTripsHasLootItems />} />
        </Routes>
      </Box>
  );
}

export default TripsHasLootItemsPage;
