import { useState, useEffect } from "react";
import { RiCreativeCommonsZeroFill } from "react-icons/ri";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import TableRow from "./MoonsTableRow";
import axios from "axios";
import { 
  Box, 
  Heading, 
  Flex, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  TableContainer, 
  Text,
  Input,
  Select,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

const MoonsTable = () => {
  const [moons, setMoons] = useState([]);
  const [sortBy, setSortBy] = useState('moonID');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    name: '',
    visitCost: '',
    riskLevel: ''
  });

  const fetchMoons = async () => {
    try {
      // Build query parameters
      const params = new URLSearchParams({
        sortBy,
        sortOrder,
        ...Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== '')
        )
      });

      const URL = `${import.meta.env.VITE_API_URL}moons?${params}`;
      const response = await axios.get(URL);
      setMoons(response.data);
    } catch (error) {
      alert("Error fetching moons from the server.");
      console.error("Error fetching moons:", error);
    }
  };

  useEffect(() => {
    fetchMoons();
  }, [sortBy, sortOrder, filters]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Box p={4} maxWidth="1000px" mx="auto" textAlign="center">
      <Text mb={4}>
        The table below displays all known moons. Click the button above to add a new moon. 
        To update or delete a moon, click the respective button in the same row as the moon. 
        Please note, a moon associated with a trip cannot be deleted!
      </Text>
  
      {/* Filter Controls */}
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
        <Wrap spacing={{ base: 2, md: 4 }} justify="center">
          <WrapItem>
            <Input
              placeholder="Filter by Name"
              value={filters.name}
              onChange={(e) => handleFilterChange('name', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <NumberInput
              min={0}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
              value={filters.visitCost}
              onChange={(valueString) => handleFilterChange('visitCost', valueString)}
            >
              <NumberInputField placeholder="Filter by Visit Cost" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Risk Level"
              value={filters.riskLevel}
              onChange={(e) => handleFilterChange('riskLevel', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            >
              <option value="S++">S++</option>
              <option value="S+">S+</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
            </Select>
          </WrapItem>
        </Wrap>
      </Box>
  
      {moons.length === 0 ? (
        <Box as="header" py={5} px={10} textAlign="center">
          <Flex align="center" justify="center" direction="column">
            <RiCreativeCommonsZeroFill size={70} color="gray" />
            <Text bg='black' px={4} py={1} mt={5}>No moons in Moons found.</Text>
          </Flex> 
        </Box>
      ) : (
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {[
                  { id: 'moonID', label: 'ID' },
                  { id: 'name', label: 'Name' },
                  { id: 'visitCost', label: 'Visit Cost' },
                  { id: 'riskLevel', label: 'Risk Level' }
                ].map(column => (
                  <Th 
                    key={column.id}
                    onClick={() => handleSort(column.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <span>{column.label}</span>
                      {getSortIcon(column.id)}
                    </Stack>
                  </Th>
                ))}
                <Th>Edit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {moons.map((moon) => (
                <TableRow 
                  key={moon.moonID} 
                  moon={moon} 
                  fetchMoons={fetchMoons} 
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default MoonsTable;