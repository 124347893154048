//Citation for the following UpdateMoon file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citation for updating placeholder text color:
// Date: 05/30/2024
// Adapted from the following URL:
// Source URL: https://github.com/chakra-ui/chakra-ui/discussions/3110#discussioncomment-297903

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Button, Box, FormControl, FormLabel, Input, Heading, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Select, Text } from '@chakra-ui/react';

const UpdateMoon = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevMoon = location.state.moon;

  // holds state variable for form data and its set function. Data in this state variable is sent to the backend when form is submitted
  const [formData, setFormData] = useState({
    name: prevMoon.name || '',
    visitCost: prevMoon.visitCost || '',
    riskLevel: prevMoon.riskLevel || ''
  });

  // Handler for when the input fields for name, visit cost and risk level are updated
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Checks if updated form data is same as previous form data and doesn't perform any updates if so
  function isUpdate(){
    // Check if formData is equal to prevMoon
    if (JSON.stringify(formData) === JSON.stringify({
      name: prevMoon.name || '',
      visitCost: prevMoon.visitCost || '',
      riskLevel: prevMoon.riskLevel || ''
    })) {
      alert("No changes made.");
      return false;
    }
    return true
  }

  // Handler for when the moons form is submitted
  const handleSubmit = async (event) => {
    // Stop default form behavior which is to reload the page
    event.preventDefault();
    // Check if formData is equal to prevMoon
    if (isUpdate()){
      try {
        const URL = import.meta.env.VITE_API_URL + "moons/" + id;
        const response = await axios.put(URL, formData);
        if (response.status !== 200) {
          alert("Error updating moon");
        } else {
          alert(response.data.message);
          // Redirect to moons page
          navigate("/moons");
        }
      } catch (err) {
        console.log("Error updating moon:", err);
      }
    }
  };

  return (
    <Box p={4}>
      <Heading as="h2" size="md" mb={4}>Update Moon</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        {/* Moon Name Input field on form */}
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            onChange={handleInputChange}
            required
            defaultValue={prevMoon.name}
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Moon Name' 
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        {/* Value Input field on form */}
        <FormControl id="visitCost" isRequired mt={4}>
          <FormLabel>Visit Cost</FormLabel>
          <NumberInput 
          min={0} 
          defaultValue= {prevMoon.visitCost}
          onChange={(valueString) => handleInputChange({ target: { name: 'visitCost', value: valueString } })} // Handler for when value field value changes. Convert from number to event object
          bg="rgba(0, 0, 0, 0.80)"
          >
        <NumberInputField 
          name="visitCost"
          required 
        />
          <NumberInputStepper>
          <NumberIncrementStepper       
            bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='+'/>
            <NumberDecrementStepper             
              bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='-'/>
          </NumberInputStepper>
        </NumberInput>
        </FormControl>

        {/* Risk Level dropdown field on form */}
        <FormControl id="riskLevel" isRequired mt={4}>
          <FormLabel>Risk Level</FormLabel>
          <Select
            name="riskLevel"
            onChange={handleInputChange}
            defaultValue={prevMoon.riskLevel}
            required
            placeholder='Select Risk Level Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'
          >
            <option value="D">D</option>
            <option value="C">C</option>
            <option value="B">B</option>
            <option value="A">A</option>
            <option value="S+">S+</option>
            <option value="S++">S++</option>
          </Select>
        </FormControl>

        <Box mt={4} display="flex" justifyContent="space-between">
          <Button _hover={{ bg: "#7cfc7c" }} onClick={() => navigate("/moons")}>
            Cancel
          </Button>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateMoon;
