//Citation for the following function SearchBar:
//Date: 05/16/2024
// onChange handler copied and Adapted from:
//Source URL: https://salehmubashar.com/blog/create-a-search-bar-in-react-js

//Date: 05/26/2024
// Styling for Search Bar adapted from:
// https://v2.chakra-ui.com/docs/components/heading
// https://v2.chakra-ui.com/docs/components/input

import { React, useState } from "react";
import { Box, Input, Heading } from "@chakra-ui/react";

// SearchBar takes inputText (the text typed in the search bar) and setInputText updates this state on the TripsPage
function SearchBar({ inputText, setInputText }) {
  // handler function for when text is entered into the search bar. 
  const inputHandler = (e) => { 
    // takes the text entered in the search bar and converts to lower case
    const lowerCaseText = e.target.value.toLowerCase(); 
    // update the inputText state variable
    setInputText(lowerCaseText);
  };

  return (
    <Box p={4}>
      <Heading as="h2" size="md" mb={4}>Search</Heading>
        <Input
          type="text"
          maxW='1400px'
          mx="auto"
          value={inputText}
          onChange={inputHandler}
          bg="rgba(0, 0, 0, 0.80)"
          placeholder="Enter an item to search for in the table"
          _placeholder={{ color: '#00ff00' }}
        />
    </Box>
  );
}


export default SearchBar;
