//Citation for the following MoonsPage file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import { Routes, Route, Link } from "react-router-dom";
import CreateMoon from "../components/Moons/CreateMoon";
import MoonsTable from "../components/Moons/MoonsTable";
import UpdateMoon from "../components/Moons/UpdateMoon";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { BiPlus, BiTable } from "react-icons/bi";

function MoonsPage() {
  const location = useLocation();
  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={4}>Moons</Heading>
      <Flex as="nav" justify="center" mb={4}>
      {(location.pathname == "/moons/add" || location.pathname.startsWith( "/moons/edit/"))  && (
        <Button as={Link} to="/moons" mr={2} leftIcon={<BiTable />} _hover={{ bg: "#7cfc7c" }}>
          Moons Table
        </Button>
      )}
      
      {location.pathname === "/moons" && (<Button as={Link} to="/moons/add" mr={2} leftIcon={<BiPlus />} _hover={{ bg: "#7cfc7c" }}>
          Add Moon
        </Button>
      )}
      </Flex>
      <Routes>
        <Route path="/" element={<MoonsTable />} />
        <Route path="/add" element={<CreateMoon />} />
        <Route path="/edit/:id" element={<UpdateMoon />} />
      </Routes>
      </Box>
  );
}

export default MoonsPage;
