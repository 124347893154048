//Citation for the following CreateMoon file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citation for updating placeholder text color:
// Date: 05/30/2024
// Adapted from the following URL:
// Source URL: https://github.com/chakra-ui/chakra-ui/discussions/3110#discussioncomment-297903

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Box, FormControl, FormLabel, Input, Heading, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Select, Text } from '@chakra-ui/react';

function CreateMoon() {
  const navigate = useNavigate();

  // formData state variable that holds data to send to backend when form is submitted
  const [formData, setFormData] = useState({
    name: "",
    visitCost: "",
    riskLevel: "",
  });
  
  const handleSubmit = async (e) => {
    // Prevent page reload
    e.preventDefault();
    // Create a new moon object from the formData
    const newMoon = {
      name: formData.name,
      visitCost: formData.visitCost,
      riskLevel: formData.riskLevel,
    };

    try {
      const URL = import.meta.env.VITE_API_URL + "moons";
      const response = await axios.post(URL, newMoon);
      if (response.status === 201) {
        navigate("/moons");
        alert("Successfully created moon");
      } else {
        alert("Error creating moon");
      }
    } catch (error) {
      alert("Error creating moon");
      console.error("Error creating moon:", error);
    }
    // Reset the form fields
    resetFormFields();
  };

  // reset form state variable when form is reset
  const resetFormFields = () => {
    setFormData({
      name: "",
      visitCost: "",
      riskLevel: "",
    });
  };

  // Handler for when the input fields for name, visit cost and risk level are updated
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Heading as="h2" size="md" mb={4}>Create Moon</Heading> 
      <Box as="form" onSubmit={handleSubmit}>
        {/* Moon Name Input field on form */}
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            defaultValue={formData.name}
            onChange={handleInputChange}
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Moon Name'
            _placeholder={{ color: '#00ff00' }} 
          />
        </FormControl>

        {/* Value Input field on form */}
        <FormControl id="visitCost" isRequired mt={4}>
          <FormLabel>Visit Cost</FormLabel>
          <NumberInput 
          min={0} 
          defaultValue={formData.value || 0 } // Use 0 if formData.visitCost is undefined or null
          onChange={(valueString) => handleInputChange({ target: { name: 'visitCost', value: valueString } })} // Handler for when value field value changes. Convert from number to event object
          bg="rgba(0, 0, 0, 0.80)"
          >
        <NumberInputField 
          name="visitCost"
          required 
        />
          <NumberInputStepper>
            <NumberIncrementStepper       
            bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='+'/>
            <NumberDecrementStepper             
              bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='-'/>
          </NumberInputStepper>
        </NumberInput>
        </FormControl>



        {/* Risk Level dropdown field on form */}
        <FormControl id="riskLevel" isRequired mt={4}>
          <FormLabel>Risk Level</FormLabel>
          <Select
            name="riskLevel"
            value={formData.riskLevel}
            onChange={handleInputChange}
            required
            placeholder='Select Risk Level Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'
          >
            <option value="D">D</option>
            <option value="C">C</option>
            <option value="B">B</option>
            <option value="A">A</option>
            <option value="S+">S+</option>
            <option value="S++">S++</option>
          </Select>
        </FormControl>

        <Box mt={4}>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Create Moon
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default CreateMoon;