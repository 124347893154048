//Citation for the following function ResetButton:
//Date: 05/18/2024
//Adapted from:
//Source URL: https://www.robinwieruch.de/react-button/

import React, { useState } from 'react';
import axios from 'axios';
import { Button, Box, Text } from '@chakra-ui/react';
import { MdRefresh } from "react-icons/md";

const ResetButton = () => {
  // set state variable for resetStatus and its set function. Initialize to null.  
  const [resetStatus, setResetStatus] = useState(null);

  // Event handler for when the user clicks the reset database button.
  const handleClick = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "resetdatabase";
      const response = await axios.get(URL); // execute DDL queries in resetdatabase backend controller to reset database to initial values
      if (response.status === 200) {
        setResetStatus('Database reset successfully'); // set resetStatus to success string if DDL queries successfully executed
      } else {
        setResetStatus('Failed to reset database'); // set resetStatus to failure string if DDL queries failed to execute
      }
    } catch (error) {
      console.error("Error resetting database:", error);
      setResetStatus('Error resetting database');
    }
  };

  return (
    <Box textAlign="center" mt={4}>
      <Button 
        leftIcon={<MdRefresh />} 
        onClick={handleClick}
        variant={'solid'}
      >
        Reset Database
      </Button>
      {/* If resetStatus is not null (reset was either a success or failure), display the success or failure string under resetDatabase button */}
      {resetStatus && <Text mt={2}>{resetStatus}</Text>} 
    </Box>
  );
};

export default ResetButton;
