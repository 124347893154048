// Citation for body styling
// Date: written 9/11/2018, retrieved 5/27/2024
// Background intended to mimic old-school monitors adapted from example:
// Source URL: https://css-tricks.com/old-timey-terminal-styling/ 

// Citations for Chakra UI Configurations:
// Date: 05/28/2024
// Based on Custom Theme documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/styled-system/customize-theme


import { Table, Thead, border, extendTheme } from "@chakra-ui/react";
import '@fontsource/workbench';
import '@fontsource/sixtyfour';
import '@fontsource/dotgothic16';

export const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        background: "radial-gradient(#003500, black 100%)",
        color: "#00ff00",
        backgroundSize: "100% 100%",
        minHeight: "100vh",
        maxWidth: "90vw",
      },
      table: {
        borderCollapse: "collapse",
        width: "100%",
        margin: "1em 0",
        color: "#00ff00",
      },
      th: {
        color: "#00ff00",
        padding: "0.5em",
        border: "1px solid #555555",
        whiteSpace: "wrap",
        maxWidth: "150px",        
      },
      td: {
        padding: "0.5em",
        border: "2px dotted #00cc00",
        whiteSpace: "wrap",
      },
      'thead th': {
        backgroundColor: "#00cc00",
        color: "#00ff00",
        fontWeight: "bold",
      },
      'tbody tr:nth-of-type(odd)': {
        backgroundColor: "#3b3b3b",
      },
      'tbody tr:nth-of-type(even)': {
        backgroundColor: "#2b2b2b",
      },
    },
  },
  components: {
    Button: {
      variants: {
        'solid': {
          bg: '#00ff00',
          border: '#00ff00 3px',
          boxShadow: '0 0 5px #00ff00',
        } 
      }
    },
    Select: {
      baseStyle: {
        field: {
          bg: 'rgba(0, 0, 0, 0.80)',
          "& option": {
            background: "rgba(0, 0, 0, 0.80)"
          },
          "& option:disabled": {
            background: "rgba(0, 0, 0, 0.80)"
          }
        }
      },
      variants: {
        filled: {
          field: {
            bg: 'rgba(0, 0, 0, 0.80)',
            "&:hover": {
              bg: 'rgba(0, 0, 0, 0.60)',
            },
            "&:focus": {
              bg: 'rgba(0, 0, 0, 0.70)',
            },
            "& option": {
              background: "rgba(0, 0, 0, 0.80)"
            },
            "& option:disabled": {
              background: "rgba(0, 0, 0, 0.80)"
            }
          }
        }
      }
    }
  },
      // Table: {
    //   simple: {
    //     Th: {
    //       fontSize: '400px'
    //     }
    //   }
    // }
  fonts: {
    heading: `'Sixtyfour', monospace`,
    body: `'DotGothic16', sans-serif`,
  }
});