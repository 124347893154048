//Citation for the following TripsHasMonstersPage file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import { Routes, Route, Link } from "react-router-dom";
import CreateTripsHasMonsters from "../components/TripsHasMonsters/CreateTripsHasMonsters";
import TripsHasMonstersTable from "../components/TripsHasMonsters/TripsHasMonstersTable";
import UpdateTripsHasMonsters from "../components/TripsHasMonsters/UpdateTripsHasMonsters";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

function TripsHasMonstersPage() {
  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={4}>Monster Encounters</Heading>
      <Routes>
        <Route path="/" element={<TripsHasMonstersTable />} />
        <Route path="/add" element={<CreateTripsHasMonsters />} />
        <Route path="/edit/:id" element={<UpdateTripsHasMonsters />} />
      </Routes>
      </Box>
  );
}

export default TripsHasMonstersPage;
