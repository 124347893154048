import { useState, useEffect } from "react";
import { RiCreativeCommonsZeroFill } from "react-icons/ri";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import TableRow from "./TripsTableRow";
import axios from "axios";
import { 
  Box, 
  Heading, 
  Flex, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  TableContainer, 
  Text,
  Input,
  Select,
  HStack,
  VStack,
  Stack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";

const TripsTable = ({ searchText }) => {
  const [trips, setTrips] = useState([]);
  const [sortBy, setSortBy] = useState('tripID');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    date: '',
    quotaMet: '',
    employee: searchText || '', // Initialize with searchText if provided
    moon: '',
    weather: '',
    lootItems: '',
    monsters: ''
  });

  const fetchTrips = async () => {
    try {
      // Build query parameters
      const params = new URLSearchParams({
        sortBy,
        sortOrder,
        ...Object.fromEntries(
          Object.entries(filters).filter(([_, value]) => value !== '')
        )
      });

      const URL = `${import.meta.env.VITE_API_URL}trips?${params}`;
      const response = await axios.get(URL);
      setTrips(response.data);
    } catch (error) {
      alert("Error fetching trips from the server.");
      console.error("Error fetching trips:", error);
    }
  };

  // Update employee filter when searchText prop changes
  useEffect(() => {
    setFilters(prev => ({
      ...prev,
      employee: searchText || ''
    }));
  }, [searchText]);

  useEffect(() => {
    fetchTrips();
  }, [sortBy, sortOrder, filters]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const getSortIcon = (column) => {
    if (sortBy !== column) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Box p={4} maxWidth="1400px" mx="auto" textAlign="center">
      <Text mb={4}>
        The table below displays all completed trips. Click the button above to add a new trip. 
        To update or delete a trip, click the respective button in the same row as the trip. 
        Use the filters below to search for specific trips.
      </Text>
  
      {/* Filter Controls */}
      <Box mb={4} p={4} borderWidth="1px" borderRadius="lg">
        <Wrap spacing={{ base: 2, md: 4 }} justify="center">
          <WrapItem>
            <Input
              type="date"
              value={filters.date}
              onChange={(e) => handleFilterChange('date', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Select
              placeholder="Filter by Quota Met"
              value={filters.quotaMet}
              onChange={(e) => handleFilterChange('quotaMet', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Employee"
              value={filters.employee}
              onChange={(e) => handleFilterChange('employee', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Moon"
              value={filters.moon}
              onChange={(e) => handleFilterChange('moon', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Weather"
              value={filters.weather}
              onChange={(e) => handleFilterChange('weather', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Loot Items"
              value={filters.lootItems}
              onChange={(e) => handleFilterChange('lootItems', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
          <WrapItem>
            <Input
              placeholder="Filter by Monsters"
              value={filters.monsters}
              onChange={(e) => handleFilterChange('monsters', e.target.value)}
              size="sm"
              width={{ base: "full", md: "auto" }}
              minW={{ base: "200px", md: "160px" }}
            />
          </WrapItem>
        </Wrap>
      </Box>
  
      {trips.length === 0 ? (
        <Box as="header" py={5} px={10} textAlign="center">
          <Flex align="center" justify="center" direction="column">
            <RiCreativeCommonsZeroFill size={70} color="gray" />
            <Text bg='black' px={4} py={1} mt={5}>No trips found matching the current filters.</Text>
          </Flex> 
        </Box>
      ) : (
        <TableContainer>
          <Table variant='simple' maxW='1400px' mx="auto">
            <Thead>
              <Tr>
                {[
                  { id: 'tripID', label: 'ID' },
                  { id: 'date', label: 'Date' },
                  { id: 'quotaMet', label: 'Quota Met' },
                  { id: 'employee', label: 'Employee' },
                  { id: 'moon', label: 'Moon' },
                  { id: 'weather', label: 'Weather' },
                  { id: 'loot_items', label: 'Loot Found', sortable: false },
                  { id: 'monsters', label: 'Monsters Seen', sortable: false }
                ].map(column => (
                  <Th 
                    key={column.id}
                    onClick={() => !column.sortable && handleSort(column.id)}
                    cursor={column.sortable === false ? 'default' : 'pointer'}
                    _hover={{ bg: column.sortable === false ? 'transparent' : "gray.100" }}
                  >
                    <Stack direction="row" spacing={2}>
                      <span>{column.label}</span>
                      {!column.sortable && getSortIcon(column.id)}
                    </Stack>
                  </Th>
                ))}
                <Th>Edit</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {trips.map((trip) => (
                <TableRow 
                  key={trip.tripID} 
                  trip={trip} 
                  fetchTrips={fetchTrips} 
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TripsTable;