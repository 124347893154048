//Citation for the following CreateLootItem file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Box, FormControl, FormLabel, Input, Heading, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Select, Text } from '@chakra-ui/react';

function CreateLootItem() {
  const navigate = useNavigate();

  // formData state variable that holds data to send to backend when form is submitted
  const [formData, setFormData] = useState({
    name: "",
    value: "",
    weight: "",
    action: "",
  });
  
  const handleSubmit = async (e) => {
    // Prevent page reload
    e.preventDefault();
    // Create a new loot item object from the formData
    const newLootItem = {
      name: formData.name,
      value: formData.value,
      weight: formData.weight,
      action: formData.action,
    };

    try {
      const URL = import.meta.env.VITE_API_URL + "lootitems";
      const response = await axios.post(URL, newLootItem);
      if (response.status === 201) {
        navigate("/lootitems");
        alert("Successfully created loot item");
      } else {
        alert("Error creating loot item");
      }
    } catch (error) {
      alert("Error creating loot item");
      console.error("Error creating loot item:", error);
    }
    // Reset the form fields
    resetFormFields();
  };

  // reset form state variable when form is reset
  const resetFormFields = () => {
    setFormData({
      name: "",
      value: "",
      weight: "",
      action: "",
    });
  };

  // Handler for when the input fields for name, value, weight and action are updated
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Heading as="h2" size="md" mb={4}>Create Loot Item</Heading>
      <Box as="form" onSubmit={handleSubmit}>

        {/* Loot Item Name Input field on form */}
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            defaultValue={formData.name} // set value to location stored in formData state variable
            onChange={handleInputChange} // Handler for when input field value changes
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Loot Item Name' 
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        {/* Value Input field on form */}
        <FormControl id="value" isRequired mt={4}>
          <FormLabel>Value</FormLabel>
          <NumberInput 
          min={0} 
          defaultValue={formData.value} // set value to location stored in formData state variable
          onChange={(valueString) => handleInputChange({ target: { name: 'value', value: valueString } })} // Handler for when value field value changes. Convert from number to event object
          bg="rgba(0, 0, 0, 0.80)"
          
          >
        <NumberInputField 
          name="value"
          required 
        />
          <NumberInputStepper>
          <NumberIncrementStepper       
            bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='+'/>
            <NumberDecrementStepper             
              bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='-'/>
          </NumberInputStepper>
        </NumberInput>
        </FormControl>

        {/* Weight Input field on form */}
          <FormControl id="weight" isRequired mt={4}>
          <FormLabel>Weight</FormLabel>
          <NumberInput 
          min={0} 
          defaultValue={formData.weight} // set value to location stored in formData state variable
          onChange={(valueString) => handleInputChange({ target: { name: 'weight', value: valueString } })} // Handler for when weight field value changes. Convert from number to event object
          bg="rgba(0, 0, 0, 0.80)"
          >
        <NumberInputField 
          name="weight"
          required 
        />
          <NumberInputStepper>
          <NumberIncrementStepper       
            bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='+'/>
            <NumberDecrementStepper             
              bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='-'/>
          </NumberInputStepper>
        </NumberInput>
          </FormControl>

        {/* Action Input field on form */}
        <FormControl id="action" mt={4}>
          <FormLabel>Action</FormLabel>
          <Input 
            type="text" 
            name="action" 
            value={formData.action}  // set value to location stored in formData state variable
            onChange={handleInputChange} // Handler for when input field value changes
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Action (If Any)' 
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        <Box mt={4}>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Create Loot Item
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default CreateLootItem;