//Citation for the following moonsDropdown file and function:
//Date: 05/21/2024
// fetchMoons function and mapping method of response from fetchMoons to dropdown list inspired from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

// defaultValue is the moon used by default when updating a trip if previously selected
const MoonsDropdown = ({ onChange, defaultValue }) => {

  // declare state variable for moons and setMoons to update state variable. Holds the list of moons fetched from the backend.
  const [moons, setMoons] = useState([]);

  // declare state variable for moonID and setSelectedMoonID to update state variable. Holds the ID of the selected moon.
  const [selectedMoonID, setSelectedMoonID] = useState("");

  const fetchMoons = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + 'moons';
      const response = await axios.get(URL);
      setMoons(response.data);
      console.log('Fetched moons:', response.data);

      // Map defaultValue (moon_name) to moonID
      const defaultMoon = response.data.find(moon => moon.name === defaultValue);
      if (defaultMoon) {
        setSelectedMoonID(defaultMoon.moonID);
        onChange(defaultMoon);
      }
    } catch (error) {
      console.error('Error fetching moons:', error);
      alert('Error fetching moons from the server.');
    }
  };

  // effect hook to fetch moons
  useEffect(() => {
    fetchMoons();
  }, []);

  const handleChange = (event) => {
    const moonID = event.target.value; // set moonID to selected value from dropdown event value
    console.log('Moon selected:', moonID); // debugging statement, to be removed later
    setSelectedMoonID(moonID);
    const selectedMoon = moons.find(moon => moon.moonID === parseInt(moonID));
    onChange(selectedMoon);
  };

  return (
    <FormControl isRequired>
      <Select placeholder="Select a Moon" onChange={handleChange} value={selectedMoonID} bg="rgba(0, 0, 0, 0.80)" variant='filled'>
        {moons.map((moon) => (
          <option key={moon.moonID} value={moon.moonID}>
            {moon.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
export default MoonsDropdown;
