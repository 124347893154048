//Citation for the following CreateMonster file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citation for updating placeholder text color:
// Date: 05/30/2024
// Adapted from the following URL:
// Source URL: https://github.com/chakra-ui/chakra-ui/discussions/3110#discussioncomment-297903

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Box, FormControl, FormLabel, Input, Heading, Select, Text } from '@chakra-ui/react';

function CreateMonster() {
  const navigate = useNavigate();

  // formData state variable that holds data to send to backend when form is submitted
  const [formData, setFormData] = useState({
    species: "",
    killable: "",
    location: "",
    hostile: "",
  });
  
  const handleSubmit = async (e) => {
    // Prevent page reload
    e.preventDefault();
    // Create a new monster object from the formData
    const newMonster = {
      species: formData.species,
      killable: formData.killable,
      location: formData.location,
      hostile: formData.hostile,
    };

    try {
      const URL = import.meta.env.VITE_API_URL + "monsters";
      const response = await axios.post(URL, newMonster);
      if (response.status === 201) {
        navigate("/monsters");
        alert("Successfully created monster");
      } else {
        alert("Error creating monster");
      }
    } catch (error) {
      alert("Error creating monster");
      console.error("Error creating monster:", error);
    }
    // Reset the form fields
    resetFormFields();
  };

  // reset form state variable when form is reset
  const resetFormFields = () => {
    setFormData({
      species: "",
      killable: "",
      location: "",
      hostile: "",
    });
  };

  // Handler for when the input fields for species, killable, location and hostile attributes are updated
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Heading as="h2" size="md" mb={4}>Create Monster</Heading>
      
      <Box as="form" onSubmit={handleSubmit}>
        {/* Monster Species Input field on form */}
        <FormControl id="species" isRequired>
          <FormLabel>Species</FormLabel>
          <Input
            type="text"
            name="species"
            defaultValue={formData.species} // set value to species stored in formData state variable
            onChange={handleInputChange} // Handler for when input field value changes
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Monster Species Name' 
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        {/* Killable dropdown field on form */}
        <FormControl id="killable" isRequired mt={4}>
          <FormLabel>Killable</FormLabel>
          <Select 
            name="killable" 
            value={formData.killable} // set value to killable stored in formData state variable
            onChange={handleInputChange} // Handler for when select field value changes
            required
            placeholder='Select Killable Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Select>
        </FormControl>

        {/* Monster location dropdown field on form */}
        <FormControl id="location" isRequired mt={4}>
          <FormLabel>Location</FormLabel>
          <Select
            type="text"
            name="location"
            value={formData.location} // set value to location stored in formData state variable
            onChange={handleInputChange} // Handler for when input field value changes
            required
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Monster Location' 
            _placeholder={{ color: '#00ff00' }}
          >
            <option value="Indoor">Indoor</option>
            <option value="Outdoor">Outdoor</option>
        </Select>
        </FormControl>

        {/* Hostile dropdown field on form */}
        <FormControl id="hostile" isRequired mt={4}>
          <FormLabel>Hostile</FormLabel>
          <Select 
            name="hostile" 
            value={formData.hostile} // set value to hostile stored in formData state variable
            onChange={handleInputChange} // Handler for when select field value changes
            required
            placeholder='Select Hostile Option' 
            bg="rgba(0, 0, 0, 0.80)" 
            variant='filled'
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Select>
        </FormControl>

        <Box mt={4}>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Create Monster
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default CreateMonster;