//Citation for the following TripsPage file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import { Routes, Route, Link, useLocation  } from "react-router-dom";
import { useState } from "react"; 
import CreateTrip from "../components/Trips/CreateTrip";
import TripsTable from "../components/Trips/TripsTable";
import UpdateTrip from "../components/Trips/UpdateTrip";
import { TbTablePlus } from "react-icons/tb";
import SearchBar from "../components/SearchBar/SearchBar";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button} from "@chakra-ui/react";
import { BiPlus, BiTable } from "react-icons/bi";

function TripsPage() {
  const location = useLocation();
  const [inputText, setInputText] = useState(""); // state variable to hold current term being searched for in employee search table
  
  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={4}>Trips</Heading>
      <Flex as="nav" justify="center" mb={4}>
      {(location.pathname == "/trips/add" || location.pathname.startsWith( "/trips/edit/"))  && (
        <Button as={Link} to="/trips" mr={2} leftIcon={<BiTable />} _hover={{ bg: "#7cfc7c" }}>
          Trips Table
        </Button>
      )}
      {location.pathname === "/trips" && (<Button as={Link} to="/trips/add" mr={2} leftIcon={<BiPlus />} _hover={{ bg: "#7cfc7c" }}>
          Add Trip
        </Button>
      )}
      </Flex>
      <Routes>
      <Route path="/" element={<TripsTable searchText={inputText} />} />
        <Route path="/add" element={<CreateTrip />} />
        <Route path="/edit/:id" element={<UpdateTrip />} />
      </Routes>
      {/*Only render search bar component on /trips page. Pass user input search text and its set function */}
      {location.pathname === "/trips" && (<SearchBar inputText={inputText} setInputText={setInputText} /> 
      )}
    </Box>
  );
}

export default TripsPage;
