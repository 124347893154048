import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
const TableRow = ({ tripshaslootitems, fetchTripsHasLootItems}) => {
  // Hook that allows us to navigate programmatically
  const navigate = useNavigate();
  // Redirect to edit person page
  const handleEdit = () => {
    // We can access the id (and query the person) with useParams() in the UpdateMonster component
    navigate("/tripshaslootitems/edit/" + tripshaslootitems.tripLootItemID, { state: { tripshaslootitems } });
  };

  const deleteRow = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "tripshaslootitems/" + tripshaslootitems.tripLootItemID;
      const response = await axios.delete(URL);
      // Ensure that the tripLootItem was deleted successfully
      if (response.status === 204) {
        alert("tripLootItem deleted successfully");
      }
    } catch (err) {
      alert(err.response.data.error || "Error deleting tripLootItem");
      console.log(err);
    }
    fetchTripsHasLootItems();
  };

  return (
    <Tr key={tripshaslootitems.tripLootItemID}>
      <Td>{tripshaslootitems.tripLootItemID}</Td>
      <Td>{tripshaslootitems.trip}</Td>
      <Td>{tripshaslootitems.name}</Td>
      <Td>{tripshaslootitems.quantity}</Td>
      {/* <td>
        <BiEditAlt onClick={handleEdit} size={25} style={{ cursor: "pointer" }} />
      </td>
      <td>
        <BsTrash onClick={deleteRow} size={25} style={{ cursor: "pointer" }} />
      </td> */}
    </Tr>
  );
};

export default TableRow;
