//Citation for the following UpdateLootItem file and function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

// Citations for Chakra UI Configurations:
//Date: 05/28/2024
// Styling for Buttons was based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/button/usage

// Styling for Forms based on documentation provided on Chakra UI's website:
// Source URL: https://v2.chakra-ui.com/docs/components/form-control

import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Button, Box, FormControl, FormLabel, Input, Heading, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Select, Text } from '@chakra-ui/react';

const UpdateLootItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const prevLootItem = location.state.lootItem;

  // holds state variable for form data and its set function. Data in this state variable is sent to the backend when form is submitted
  const [formData, setFormData] = useState({
    name: prevLootItem.name || '',
    value: prevLootItem.value || '',
    weight: prevLootItem.weight || '',
    action: prevLootItem.action || '',
  });

  // Handler for when the input fields for name, value, weight and action are updated input fields are updated
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Checks if updated form data is same as previous form data and doesn't perform any updates if so
  function isUpdate(){
    // Check if formData is equal to prevLootItem
    if (JSON.stringify(formData) === JSON.stringify({
      name: prevLootItem.name || '',
      value: prevLootItem.value || '',
      weight: prevLootItem.weight || '',
      action: prevLootItem.action || '',
    })) {
      alert("No changes made.");
      return false;
    }
    return true
  }

  // Handler for when the LootItems form is submitted
  const handleSubmit = async (event) => {
    // Stop default form behavior which is to reload the page
    event.preventDefault();
    // Check if formData is equal to prevLootItem
    if (isUpdate()){
      try {
        const URL = import.meta.env.VITE_API_URL + "lootitems/" + id;
        const response = await axios.put(URL, formData);
        if (response.status !== 200) {
          alert("Error updating loot item");
        } else {
          alert(response.data.message);
          // Redirect to loot items page
          navigate("/lootitems");
        }
      } catch (err) {
        console.log("Error updating loot item:", err);
      }
    }
  };

  return (
    <Box p={4}>
      <Heading as="h2" size="md" mb={4}>Update Loot Item</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        
        {/* Loot Item Name Input field on form */}
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            name="name"
            onChange={handleInputChange} // Handler for when input field value changes
            required
            defaultValue={prevLootItem.name} // set value to location stored in formData state variable
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Loot Item Name' 
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        {/* Value Input field on form */}
        <FormControl id="value" isRequired mt={4}>
          <FormLabel>Value</FormLabel>
          <NumberInput 
          min={0} 
          defaultValue={formData.value} // set value to location stored in formData state variable
          onChange={(valueString) => handleInputChange({ target: { name: 'value', value: valueString } })} // Handler for when value field value changes. Convert from number to event object
          bg="rgba(0, 0, 0, 0.80)"
          >
        <NumberInputField 
          name="value"
          required 
        />
          <NumberInputStepper>
          <NumberIncrementStepper       
            bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='+'/>
            <NumberDecrementStepper             
              bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='-'/>
          </NumberInputStepper>
        </NumberInput>
        </FormControl>

        {/* Weight Input field on form */}
        <FormControl id="weight" isRequired mt={4}>
          <FormLabel>Weight</FormLabel>
          <NumberInput 
          min={0} 
          defaultValue={formData.weight} // set value to location stored in formData state variable
          onChange={(valueString) => handleInputChange({ target: { name: 'weight', value: valueString } })} // Handler for when weight field value changes. Convert from number to event object
          bg="rgba(0, 0, 0, 0.80)"
          >
        <NumberInputField 
          name="weight"
          required 
        />
          <NumberInputStepper>
          <NumberIncrementStepper       
            bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='+'/>
            <NumberDecrementStepper             
              bg='#00ff00'
              _active={{ bg: '#00ff00' }}
              children='-'/>
          </NumberInputStepper>
        </NumberInput>
          </FormControl>

        {/* Action Input field on form */}
        <FormControl id="action" mt={4}>
          <FormLabel>Action</FormLabel>
          <Input
            type="text"
            name="action"
            onChange={handleInputChange} // Handler for when input field value changes
            defaultValue={prevLootItem.action} // set value to location stored in formData state variable
            bg="rgba(0, 0, 0, 0.80)"
            placeholder='Enter Action (If Any)' 
            _placeholder={{ color: '#00ff00' }}
          />
        </FormControl>

        <Box mt={4} display="flex" justifyContent="space-between">
          <Button colorScheme="gray" onClick={() => navigate("/lootitems")} _hover={{ bg: "#7cfc7c" }}>
            Cancel
          </Button>
          <Button _hover={{ bg: "#7cfc7c" }} type="submit">
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateLootItem;