//Citation for the following LootItemsTableRow file and TableRow function:
//Date: 05/12/2024
//Based on and adapted from the OSU CS340 React Starter App code provided:
//Source URL: https://github.com/osu-cs340-ecampus/react-starter-app

import axios from "axios";
import { BsTrash } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Flex, Link as ChakraLink, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
const TableRow = ({ lootItem, fetchLootItems }) => {
  // Hook that allows us to navigate programmatically
  const navigate = useNavigate();
  // Redirect to edit person page
  const handleEdit = () => {
    // We can access the id (and query the person) with useParams() in the UpdateLootItem component
    navigate("/lootitems/edit/" + lootItem.lootItemID, { state: { lootItem } });
  };

  const deleteRow = async () => {
    try {
      const URL = import.meta.env.VITE_API_URL + "lootitems/" + lootItem.lootItemID;
      const response = await axios.delete(URL);
      // Ensure that the loot item was deleted successfully
      if (response.status === 204) {
        alert("Loot Item deleted successfully");
      }
    } catch (err) {
      alert(err.response.data.error || "Error deleting loot item");
      console.log(err);
    }
    fetchLootItems();
  };

  return (
    <Tr key={lootItem.lootItemID}> {/*Display values from lootItems object in state variable passed in from LootItemsTable  */}
      <Td>{lootItem.lootItemID}</Td>
      <Td>{lootItem.name}</Td>
      <Td>{lootItem.value}</Td>
      <Td>{lootItem.weight}</Td>
      <Td>{lootItem.action}</Td>
      <Td>
        <BiEditAlt onClick={handleEdit} size={25} style={{ cursor: "pointer" }} />
        </Td>
      <Td>
        <BsTrash onClick={deleteRow} size={25} style={{ cursor: "pointer" }} />
        </Td>
      </Tr>
  );
};

export default TableRow;
